import api, {BaseResponse, RequestMethod} from "services/api";


const uploadApi = api.injectEndpoints({
  endpoints: (build) => ({
    removeMedia: build.mutation<boolean, { type: "video" | "image", digest: string }>({
      query:             ({type, ...params}) => ({
        url:    `app/upload/${type}`,
        method: RequestMethod.DELETE,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),
  }),
});

export const {useRemoveMediaMutation} = uploadApi;