import * as  React            from "react";
import {useEffect}            from "react";
import {useSearchParams}      from "react-router-dom";
import {RootRoutes}           from "routes";
import {changeLanguage}       from "i18next";
import {ErrorNotification}    from "components/ErrorNotification";
import {useAppDispatch}       from "store";
import {setLocale}            from "store/features";
import styles                 from "./styles.module.scss";
// import {NoInternetConnection} from "./entities";
import {PhotoSwipeProvider}   from "./shared/ui/GalleryWrapper/GalleryWrapper";


function App() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const localeValue = searchParams.get("locale");

  window.fromNative = (module, action, data) => {
    //@ts-ignore
    window.lastNativeMessage = {module, action, data};
    window.dispatchEvent(new CustomEvent("nativeMessage", {detail: {module, action, data}}));
  };

  useEffect(() => {
    if (localeValue) {
      changeLanguage(localeValue);
      dispatch(setLocale(localeValue));
    }
  }, [dispatch, localeValue]);

  return <div className={styles.wrapper}>
    {/*<NoInternetConnection>*/}
      <PhotoSwipeProvider>
      <RootRoutes />
      </PhotoSwipeProvider>
    {/*</NoInternetConnection>*/}
    <ErrorNotification />
  </div>
}

export default App;
