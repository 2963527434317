import React                                         from "react";
import {useTranslation}                              from "react-i18next";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
}                                                    from "react-hook-form";
import {Button, Input, NewList}                      from "shared/v12ui";
import {InApp, CheckboxInput, LabelItem, RadioInput} from "shared/ui";
import {useToggleState}                              from "shared/lib";
import {CreateState}                                 from "../../model/createState";
import {WorkUnitItemType}                            from "../../api";
import styles                                        from "./SecondStep.module.scss";


interface Props {
  watch: UseFormWatch<CreateState>;
  register: UseFormRegister<CreateState>;
  labels?: LabelItem[];
  workUnits?: WorkUnitItemType[];
  control: Control<CreateState>;
  setValue: UseFormSetValue<CreateState>;
  errors: FieldErrors<CreateState>;
  clearErrors: UseFormClearErrors<CreateState>;
}


function SecondStepOrder({watch, errors, register, control, labels, workUnits, setValue, clearErrors}: Props) {
  const {t} = useTranslation();

  const [selectUnit, toggleSelectUnit] = useToggleState();
  const [openPaymentInfo, setOpenPaymentInfo] = React.useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[.,]/g, ""); // Удаляем точки и запятые
    if (sanitizedValue.length > 9) {
      input.value = sanitizedValue.slice(0, 9);
    } else {
      input.value = sanitizedValue;
    }
  }

  const handlePaymentsInfoButtonClick = () => {
    setValue("isAppPaymentWay", true);
    setOpenPaymentInfo(false);
  }

  const headerSubtext = <div className={styles.payment_info_subtext}>
    {Array.from({length: 4}).map((_, index) => {
      return <span key={index}>{t(`page.order.payment_info.${index}`)}</span>
    })}
  </div>

  const handleOnBeforeInput = (event: React.FormEvent<HTMLInputElement> & { data: string }) => {
    if (event.data === "." || event.data === "," || event.data === "-") {
      event.preventDefault();
    }
  }

  return <>
    <Input
      type="tel"
      inputMode="numeric"
      placeholder="0"
      dropDownValue={watch("priceUnit")}
      onDropDownClick={toggleSelectUnit}
      label={t("page.order.labels.price")}
      leftText={t("page.order.labels.to")}
      onBeforeInput={handleOnBeforeInput}
      errorMessage={errors.price?.message}
      subtext={watch("recommendedPrice") && <div className={styles.price_subtext}>
        <span>{watch("recommendedPrice.work")}:</span> <span>{watch("recommendedPrice.price")}</span>
      </div>}
      {...register("price", {
        onChange: (event) => {
          handleOnChange(event);
          clearErrors("price");
        },
      })}
    >
      <Input.Dropdown />
    </Input>

    <Controller
      control={control}
      name="isAppPaymentWay"
      render={({field}) => {
        return <NewList
          hasSubtitleClick
          title={t("page.order.labels.online_pay")}
          subtitle={t("page.order.placeholders.online_payment")}
          labels={labels}
          onClick={() => setValue("isAppPaymentWay", !field.value)}
          controlIcon={<CheckboxInput
            isNew
            checked={field.value}
            onChange={() => setValue("isAppPaymentWay", !field.value)}
          />}
          onSubtitleClick={(event) => {
            event.stopPropagation();
            setOpenPaymentInfo(true);
          }}
        />
      }}
    />

    <InApp open={selectUnit} onClose={toggleSelectUnit}>
      <div className={styles.units_holder}>
        {workUnits?.map((item) => {
          return <Controller
            key={item.name}
            control={control}
            name="priceUnit"
            render={({field}) => {
              return <RadioInput
                isNew
                id={`${item.id}`}
                key={`${item.id}`}
                value={item.name}
                label={item.name}
                checked={item.name === field.value}
                onChange={() => setValue("priceUnit", item.name)}
              />
            }}
          />
        })}
      </div>
    </InApp>

    <InApp
      open={openPaymentInfo}
      onClose={() => setOpenPaymentInfo(false)}
      image="/illustrations/payment-with-card-2.svg"
      headerText={t("page.order.labels.online_payment")}
      headerSubtext={headerSubtext}
    >
      <Button
        hasSpace
        text={t("page.order.select_online_payment_button")}
        onClick={handlePaymentsInfoButtonClick}
      />
    </InApp>
  </>
}

export default SecondStepOrder;