import api, {BaseResponse, RequestMethod} from "services/api";


const clientApi = api.injectEndpoints({
  endpoints: (build) => ({
    clientCartInit: build.mutation<{ payment_id: string } | undefined, Record<string, number | string | boolean>>({
      query:             ({...params}) => ({
        url:    `app/balance/purchase/cart/init`,
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<{
        payment_id: string
      }>) => response.status ? response.content : undefined,
    }),
  }),
});

export const {useClientCartInitMutation} = clientApi;