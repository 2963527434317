import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {InApp}                                 from "shared/ui";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {setActionForNative, useToggleState}    from "shared/lib";
import {NativeActions, Nullable}               from "shared/model";
import {AlertItemType}                         from "../../api";


interface Props {
  hasPushPermission: Nullable<boolean>;
  alert: AlertItemType;
}

export default function OrderSaveInApp({hasPushPermission, alert}: Props) {
  const {t} = useTranslation();

  const [inApp, toggleInApp] = useToggleState();

  const handleCloseClick = () => {
    toggleInApp();
    setActionForNative(NativeActions.TERMINATE);
  }

  const handleGivePushPermissionClick = () => {
    setActionForNative(NativeActions.GIVE_PUSH_PERMISSION);
    toggleInApp();
    setActionForNative(NativeActions.TERMINATE);
  }

  React.useEffect(() => {
    if (!!alert) {
      toggleInApp();
    }
  }, [alert]);

  return (
    <InApp
      image={`/illustrations/${alert?.image}.svg`}
      headerText={alert?.title}
      headerSubtext={alert?.text}
      open={inApp}
      onClose={handleCloseClick}
    >
      <div className="inApp_buttons">
        {alert?.buttons.map((button, index) => {
          return <Button
            key={index}
            hasSpace={!!hasPushPermission}
            buttonType={!hasPushPermission ? NewButtonType.WRAPPED : NewButtonType.DEFAULT}
            buttonColor={!hasPushPermission ? NewButtonColor.GRAY : NewButtonColor.BLACK}
            text={!hasPushPermission ? t("page.order.save_order.remind_later") : button.title}
            onClick={handleCloseClick}
          />
        })}
        {!hasPushPermission && <Button
          hasSpace
          text={t("page.order.save_order.set_permission")}
          onClick={handleGivePushPermissionClick}
        />}
      </div>
    </InApp>
  )
};