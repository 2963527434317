import {useEffect}                                from "react";
import {AppMode, selectAppMode, selectEntryPoint} from "store/features";
import {setActionForNative}                       from "shared/lib";
import {NativeActions}                            from "shared/model";
import {useAppSelector}                           from "store";


export const useAnalytics = () => {
  const appMode = useAppSelector(selectAppMode);
  const entryPoint = useAppSelector(selectEntryPoint);
  useEffect(() => {
    if (appMode === AppMode.SPECIALIST) {
      const entry = entryPoint && entryPoint !== "" ? entryPoint : "none";
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist referral open",
        properties: {
          "entry point": entry,
        },
      });
    }
  }, [appMode, entryPoint]);

  useEffect(() => {
    if (appMode === AppMode.CLIENT) {
      const entry = entryPoint && entryPoint !== "" ? entryPoint : "none";
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "client referral open",
        properties: {
          "entry point": entry,
        },
      });
    }
  }, [appMode, entryPoint]);
}