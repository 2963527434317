import api, {BaseResponse, RequestMethod} from "services/api";
import {ReviewsListItem, ReviewsOverview} from "services/reviews/types";

export interface ClientProfileItem {
  client: {
    name: string;
    rate?: number;
  };
  reviews_overview: ReviewsOverview;
  reviews: ReviewsListItem[];
}


const clientProfileApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchClientProfile: build.query<ClientProfileItem | undefined, number>({
      query:             (id) => ({
        url:    `pro/client`,
        method: RequestMethod.PUT,
        body:   {client_id: id},
      }),
      transformResponse: (response: BaseResponse<ClientProfileItem>) => response.status ? response.content : undefined,
    }),
  }),
});

export const {useFetchClientProfileQuery} = clientProfileApi;