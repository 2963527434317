import React                                                                 from "react";
import {useTranslation}                                                      from "react-i18next";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
}                                                                            from "react-hook-form";
import {Button, Input, Tabs, Icon, InputType, NewButtonColor, NewButtonType} from "shared/v12ui";
import {YaMap}                                                               from "widgets/yaMap";
import {InApp, InputPhone, LabelItem}                                        from "shared/ui";
import {NativeActions}                                                       from "shared/model";
import {setActionForNative}                                                  from "shared/lib";
import {useAppDispatch, useAppSelector}                                      from "store";
import {selectCurrentCity, selectIsDemo, setCurrentCity}                     from "store/features";
import {selectGeolocationPermission, toggleGeoInApp}                         from "features/permissions";
import {GeolocationRequest}                                                  from "features/permissions/geolocation";
import {CreateState}                                                         from "../../model/createState";
import {getCommunicationWayList}                                             from "../../model/communicationList";
import styles                                                                from "./ThirdStep.module.scss";


interface Props {
  register: UseFormRegister<CreateState>;
  watch: UseFormWatch<CreateState>;
  control: Control<CreateState>;
  setValue: UseFormSetValue<CreateState>;
  labels?: LabelItem[];
  errors: FieldErrors<CreateState>;
  clearErrors: UseFormClearErrors<CreateState>;
  openMap: boolean;
  setOpenMap: React.Dispatch<React.SetStateAction<boolean>>;
}

function ThirdStepOrder({openMap, register, errors, watch, control, setValue, labels, clearErrors, setOpenMap}: Props) {
  const {t} = useTranslation();

  const dispatch = useAppDispatch();
  const currentCity = useAppSelector(selectCurrentCity);
  const isDemoClient = useAppSelector(selectIsDemo);
  const hasGeoPermission = useAppSelector(selectGeolocationPermission);

  const [openInApp, setOpenInApp] = React.useState(false);
  const [addressName, setAddressName] = React.useState<string>();
  const [updatedIndex, setUpdatedIndex] = React.useState<number>();

  const {fields, remove, update, append} = useFieldArray({control, name: "addresses"});

  const handleOnUpdateAddress = (value: string, index: number) => {
    setAddressName(value);
    setOpenInApp(false);
    setOpenMap(true);
    setUpdatedIndex(index);

    const currentAddress = fields.find((_, addressIndex) => addressIndex === index);

    dispatch(setCurrentCity({
      id:   currentAddress?.city_id as number,
      name: currentAddress?.city_name as string,
    }));
  }

  const handleGetGeoCoords = () => setActionForNative(NativeActions.GET_GEO_COORDS);

  const handleToggleMap = () => setOpenMap(prev => !prev);

  const onInputClick = () => {
    if (!watch("addresses").length) {
      if (!hasGeoPermission) {
        return dispatch(toggleGeoInApp(true));
      } else {
        return setOpenMap(true);
      }
    } else {
      if (watch("addresses").length > 1) {
        setOpenInApp(true);
      }
      if (watch("addresses").length === 1) {
        setOpenMap(true);
        setAddressName(watch("addresses")[0].name);
        setUpdatedIndex(0);
      }
    }
  }

  const handleADdMoreClick = () => {
    setOpenInApp(false);
    setOpenMap(true);
    setAddressName(undefined);
    setUpdatedIndex(undefined);
  }

  const handleAddNewAddressClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setOpenInApp(true);
  }

  return <>
    <Input
      inputType={InputType.MAP}
      label={t("page.order.labels.address")}
      icon="place"
      value={watch("addresses")?.map((item) => item.name).join(" → ") ?? ""}
      readOnly
      placeholder={t("page.order.placeholders.address", {city: currentCity?.name})}
      labels={labels}
      onClick={onInputClick}
      subtext={t("page.order.hints.address")}
      rightIcon={!!watch("addresses").length ?
        <Icon
          size={20}
          iconColor="#828291"
          className="mgl-3"
          onClick={handleAddNewAddressClick}
        >
          add
        </Icon> : undefined}
    />

    <Controller
      control={control}
      name={"isAllowed"}
      render={({field}) => {
        return <Tabs
          isFullPosition
          header={t("page.order.labels.communication_way")}
          list={getCommunicationWayList(t)}
          currentTab={field.value ? 1 : 0}
          onSetCurrentTab={(value) => {
            setValue("isAllowed", value === 1);
            clearErrors("phone");
          }}
        />
      }}
    />

    {watch("isAllowed") ? <div className={styles.phone_holder}>
        <InputPhone
          icon={<div className={styles.phone_prefix + " mgr-2"}>
            <img src={"images/country_kz_2.svg"} alt="" />
            <div>{"+7"}</div>
          </div>}
          errorMessage={errors.phone?.message}
          placeholder="000 000-00-00"
          mask={"(000) 000-00-00"}
          disabled={!isDemoClient}
          type="tel"
          {...register("phone", {
            onChange: () => {
              clearErrors("phone");
            },
          })}
        />
      </div>
      : <div className={styles.subtext}>{t("page.order.hints.communication_way")}</div>}

    {openMap && <YaMap
      addresses={fields}
      onClose={handleToggleMap}
      onUpdateAddress={update}
      onSetAddress={append}
      addressName={addressName}
      updatedIndex={updatedIndex}
    />}

    <InApp open={openInApp} onClose={() => setOpenInApp(false)}>
      <div className={styles.inApp_buttons}>
        {!!fields.length && <ul className={styles.addresses_holder}>
          {fields.map((address, index) => {
            return <li key={address.id}>
              <Controller
                control={control}
                name={`addresses.${index}.name`}
                render={({field}) => <Input
                  readOnly
                  hasSpace={false}
                  value={field.value}
                  onClick={() => handleOnUpdateAddress(field.value, index)}
                  icon={<div className={styles.input_count}>{index + 1}</div>}
                  rightIcon={
                    <Icon
                      size={20}
                      iconColor="#FF1507"
                      className="mgl-3"
                      onClick={(event) => {
                        event.stopPropagation();
                        remove(index)
                      }}
                    >
                      delete
                    </Icon>
                  }
                />}
              />
            </li>
          })}
        </ul>}

        {watch("addresses").length < 5 && <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          text={t("page.order.add_more_button")}
          onClick={handleADdMoreClick}
          rightIcon={<Icon size={20} iconColor="#2B2B2B">add</Icon>}
          hasSpace
        />}
      </div>
    </InApp>

    <GeolocationRequest onGetGeoCoords={handleGetGeoCoords} onClose={handleToggleMap} />
  </>
}

export default ThirdStepOrder;