import React              from "react";
import {LabelItem, Label} from "shared/ui";
import cn                 from "classnames";
import styles             from "./NewList.module.scss";


interface Props {
  title: string;
  subtitle?: string;
  link?: { name?: string, url?: string };
  labels?: LabelItem[];
  icon?: React.ReactNode;
  controlIcon?: React.ReactNode;
  hasSpace?: boolean;
  hasSubtitleClick?: boolean;
  subtitleError?: boolean;
  onSubtitleClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onLinkClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  children?: React.ReactNode;
  childrenIsOpen?: boolean;
  disabled?: boolean;
  className?: string;
}

function NewList({
  title,
  subtitle,
  labels,
  link,
  icon,
  controlIcon,
  children,
  hasSpace = true,
  hasSubtitleClick = false,
  subtitleError = false,
  childrenIsOpen = false,
  disabled = false,
  className,
  onSubtitleClick = () => {
  },
  onClick = () => {
  },
  onLinkClick = () => {
  },
}: Props) {
  const spanRef = React.useRef<HTMLSpanElement | null>(null);
  const [expanded, setExpanded] = React.useState(false);

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    onClick && onClick(event);
  }

  React.useEffect(() => {
    if (!children) return;
    if (childrenIsOpen) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [childrenIsOpen, children]);

  React.useEffect(() => {
    if (!hasSubtitleClick) return;

    if (spanRef && spanRef.current) {
      const words = spanRef.current?.textContent?.split(" ");
      const lastWord = words?.pop();
      spanRef.current.innerHTML = `${words?.join(" ")} <span class="underline">${lastWord}</span>`;

      const lastWordElement = spanRef.current.querySelector(".underline");

      if (lastWordElement) {
        const handleClick = (event: Event) => {
          event.stopPropagation();
          onSubtitleClick?.(event as unknown as React.MouseEvent<HTMLDivElement>);
        };

        lastWordElement.addEventListener("click", handleClick as EventListener);

        // Убираем обработчик при размонтировании или изменении зависимостей
        return () => {
          lastWordElement.removeEventListener("click", handleClick as EventListener);
        };
      }
    }
  }, [hasSubtitleClick, onSubtitleClick]);

  return <div className={cn(styles.holder, className, {[styles.has_space]: hasSpace})}>
    <div
      className={cn(styles.wrapper, {[styles.disabled]: disabled})}
      onClick={handleOnClick}
    >
      {icon && <div className={`${styles.icon_left} mgr-3`}>{icon}</div>}
      <div className={styles.content}>
        {!!labels?.length && <Label labels={labels} />}
        <span className={styles.title}>{title}</span>
        {subtitle &&
          <span ref={spanRef} className={cn(styles.subtitle, {[styles.error]: subtitleError})}>{subtitle}</span>}
        {link?.name &&
          <span onClick={onLinkClick} className={cn(styles.link, {[styles.has_url]: !!link.url})}>{link.name}</span>}
      </div>
      {controlIcon && <div className={`${styles.icon_right} mgl-3`}>{controlIcon}</div>}

    </div>
    <div className={styles.children} style={{maxHeight: expanded ? `46px` : "0px"}}>{children}</div>
  </div>
}

export default React.memo(NewList);