import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams}             from "react-router-dom";
import {useTranslation}                     from "react-i18next";
import {Icon, Tabs}                         from "shared/v12ui";
import {
  useFetchSpecialistAppPricesListMutation,
  useFetchSpecialistAppPriceTabsMutation,
}                                           from "shared/api";
import {NativeActions, Nullable}            from "shared/model";
import {
  useNativeHandler,
  useSetHeaders,
  useSkeletonThrottling,
}                                           from "shared/lib";
import {NewPageLayout}                      from "entities/general";
import {PriceItem}                          from "entities/specialist";
import {SkeletonPrices, SkeletonTabs}       from "entities/skeleton";
import styles                               from "./SpecialistAppPrices.module.scss";


const i18n_prefix = "page.specialist.profile.prices.list."
export default function SpecialistAppPrices() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {id: paramId, service_id: paramServiceId} = useParams();
  const specialistId = paramId ? parseInt(paramId) : null;
  const serviceId = paramServiceId ? parseInt(paramServiceId) : null;
  const fistRenderRef = useRef(false);

  const {isSuccess} = useSetHeaders();

  const [getTabs, {data: pricesTabs, isLoading: getTabsIsLoading}] = useFetchSpecialistAppPriceTabsMutation();
  const [getPrices, {data: list, isLoading: getPricesIsLoading}] = useFetchSpecialistAppPricesListMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: getPricesIsLoading});

  const [tabId, setTabId] = useState<Nullable<number>>(serviceId);
  const [tabCount, setTabCount] = useState(2);

  const handleTabClick = (id: Nullable<number>, count: number) => {
    if (tabId === id) return;
    setTabId(id);
    setTabCount(count);
    getPrices({specialist_id: specialistId, service_id: id});
  }

  const handleBackClick = () => navigate(`/profile${specialistId ? `/${specialistId}` : ""}`);

  useEffect(() => {
    if (!fistRenderRef.current && !extendedLoading) {
      fistRenderRef.current = true;
    }
  }, [extendedLoading]);

  useEffect(() => {
    if (specialistId && isSuccess) {
      getTabs({specialist_id: specialistId, service_id: serviceId});
      getPrices({specialist_id: specialistId, service_id: serviceId});
    }
  }, [specialistId, isSuccess, serviceId]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return handleBackClick();
  });

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    isLoading={(!fistRenderRef.current ? extendedLoading : getTabsIsLoading)}
  >
    <>
      {(!fistRenderRef.current ? extendedLoading : getTabsIsLoading)
        ? <SkeletonTabs className="mgb-6" />

        : <Tabs
          list={pricesTabs?.tabs ?? []}
          currentTab={tabId}
          onSetCurrentTab={(value, count) => handleTabClick(value as Nullable<number>, count as number)}
          className={styles.tabs}
        />}

      {extendedLoading
        ? <SkeletonPrices count={tabCount} hasDark />

        : <div className={styles.prices_block}>{list?.prices?.map((price) => (
            <PriceItem
              initialOpen
              key={price.id}
              price={price}
            />
          ),
        )}</div>}

      {!list?.prices.length && !extendedLoading &&
        <span className={styles.empty_prices}>{
          !serviceId ? t(`${i18n_prefix}empty.client.none`)
            : t(`${i18n_prefix}empty.client.with_work`)}
        </span>}
    </>
  </NewPageLayout>
};