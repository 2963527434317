import React                                 from "react";
import {Icon}                                from "shared/v12ui";
import {SpecialistProfilePricesDataItem}     from "shared/model";
import {formatMoney, formatSpecialistPrices} from "shared/lib";
import styles                                from "./PriceItem.module.scss";

interface Props {
  price: SpecialistProfilePricesDataItem;
  initialOpen?: boolean;
}

export default function SpecialistPriceItem({price, initialOpen = false}: Props) {
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = React.useState(initialOpen);
  const [contentHeight, setContentHeight] = React.useState(initialOpen ? "100%" : "0px");

  const handleToggleExpanded = () => {
    setIsOpen(prev => !prev);
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }

  React.useEffect(() => {
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, []);

  return <div className={`${styles.wrapper} ${isOpen ? styles.open : ""}`}>
    <div className={styles.header} onClick={handleToggleExpanded}>
      <div className={styles.header_left}>
        <span className={styles.header_left_name}>{price.name}</span>

        <span className={styles.header_left_price}>
          {`${formatMoney(price.sum_to ?? 0, true)} / ${price.unit}`}
        </span>
      </div>

      {!price.prices.length
        ? null
        : <Icon size={24} className={`${styles.icon} ${isOpen ? styles.open : ""} text gray`}>
          keyboard_arrow_down
        </Icon>}
    </div>

    <div
      ref={contentRef}
      style={{maxHeight: isOpen ? contentHeight : "0px"}}
      className={styles.content}
    >
      {!price.prices.length ? null : <div className={styles.content_nested}>
        {price.prices.map((nestedPrice) => {
          return <div key={nestedPrice.id} className={styles.nested_price_item}>
            <span>{nestedPrice.name}</span>

            <span>{formatSpecialistPrices(nestedPrice.sum_to, nestedPrice.unit)}</span>
          </div>
        })}
      </div>}
    </div>
  </div>
};