import React from "react";


export default function useStopScrolling() {
  React.useEffect(() => {
    let stopScrolling = false;

    function handleTouchMove(e: TouchEvent) {
      if (!stopScrolling) {
        return;
      }
      e.preventDefault();
    }

    function onTouchStart() {
      stopScrolling = true;
    }

    function onTouchEnd() {
      stopScrolling = false;
    }

    window.addEventListener("touchstart", onTouchStart);
    window.addEventListener("touchend", onTouchEnd);
    window.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
  }, []);
};