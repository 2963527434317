import {dayList, orderTimeList} from "./helperLists";
import {TFunction}              from "i18next";

function getNextWeekDates() {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    dates.push(date.toISOString().split("T")[0]);
  }

  return dates;
}

export function formatWeekList(t: TFunction<"translation", undefined>) {
  let dates = getNextWeekDates();
  const isAfterNinePM = new Date().getHours() >= 21;

  if (isAfterNinePM) {
    dates = dates.slice(1);
  }

  return dates.map((date, index) => {
    const today = `${t("page.order.date_list.today")} (${dayList(t, new Date(date).getDay())})`;
    const tomorrow = `${t("page.order.date_list.tomorrow")} (${dayList(t, new Date(date).getDay())})`;
    if (index === 0) {
      return {
        text:    isAfterNinePM
                   ? tomorrow
                   : today,
        subtext: new Date(date).getDate().toString(),
        id:      date,
      };
    }

    if (index === 1 && !isAfterNinePM) {
      return {
        text:    tomorrow,
        subtext: new Date(date).getDate().toString(),
        id:      date,
      };
    }

    return {
      text:    dayList(t, new Date(date).getDay()),
      subtext: new Date(date).getDate().toString(),
      id:      date,
    };
  });
}

export function filterTimeSlots(currentDay?: string) {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  const propDay = new Date(currentDay ?? "");

  if (propDay > currentTime) {
    return orderTimeList;
  }

  return orderTimeList.filter((slot) => {
    const startHour = parseInt(slot.id.split(":")[0], 10);
    return startHour > currentHour;
  });
}