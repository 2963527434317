import React                                               from "react";
import {useNavigate}                                       from "react-router-dom";
import {useTranslation}                                    from "react-i18next";
import {SpecialistPaymentInformationCard}                  from "components/SpecialistPaymentInformationCard";
import {LoadingScreen, NewPageLayout}                      from "entities/general";
import {SkeletonLayout, SkeletonTabs}                      from "entities/skeleton";
import {Button, Icon, Tabs}                                from "shared/v12ui";
import {ButtonRenderType, NativeActions}                   from "shared/model";
import {
  setActionForNative,
  useCheckPaymentStatus,
  useNativeHandler,
  useOpenPayments,
  useSetHeaders,
  useSkeletonThrottling,
}                                                          from "shared/lib";
import {useAppDispatch, useAppSelector}                    from "store";
import {
  AppMode,
  PurchaseType,
  selectAppMode,
  selectEntryPoint,
  selectIsMyCreditsOpened,
  selectPurchaseIsLoading,
  setIsMyCredits,
}                                                          from "store/features";
import {useCreditCartInitMutation, useGetCreditsListQuery} from "services/specialists/credits";
import styles                                              from "./styles.module.scss";


const i18n_prefix = "page.specialist.credits."
const Credits = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);
  const entryPoint = useAppSelector(selectEntryPoint);
  const myCreditsIsOpened = useAppSelector(selectIsMyCreditsOpened);
  const appMode = useAppSelector(selectAppMode);

  const [currentTab, setCurrentTab] = React.useState(1);

  const {isSuccess} = useSetHeaders();

  const {
    data:       credits,
    isLoading:  creditsIsLoading,
    isFetching: creditsIsFetching,
    refetch,
  } = useGetCreditsListQuery({id: currentTab, appMode: appMode as AppMode}, {skip: !isSuccess});

  const {extendedLoading} = useSkeletonThrottling({isLoading: creditsIsLoading || creditsIsFetching});
  const [creditCartInit, {data: creditCartInitResponse}] = useCreditCartInitMutation();
  const {paymentStatusLoading} = useCheckPaymentStatus({refetch});

  const handleTabButtonClick = (id: number, type: ButtonRenderType) => {
    switch (type) {
      case ButtonRenderType.CREDIT_PREPAYMENT:
        return creditCartInit({id, type});
      case ButtonRenderType.CREDIT_ERROR:
        return creditCartInit({id, type});
    }
  }

  const handleBuyTariffClick = () => navigate("/tariffs");

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleSetCurrentTabClick = (id: number) => setCurrentTab(id);

  useOpenPayments({
    paymentId: creditCartInitResponse?.content.payment_id,
    type:      PurchaseType.CREDIT_PREPAYMENT,
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    return setActionForNative(NativeActions.TERMINATE);
  });

  React.useEffect(() => {
    if (myCreditsIsOpened) return;
    const analytics = credits?.analytics?.["credit details"];
    if (analytics && entryPoint) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist my credits opened",
        properties: {
          "entry point":    entryPoint,
          "credit details": analytics,
        },
      });
      dispatch(setIsMyCredits(true));
    }
  }, [credits, dispatch, entryPoint, myCreditsIsOpened]);

  const showFooter = (appMode === AppMode.CLIENT && credits?.content?.credits && credits.content.credits.length > 0)
    || (appMode === AppMode.SPECIALIST && currentTab === 2 && credits?.content?.credits && credits.content.credits.length > 0);

  return <>
    {paymentStatusLoading && <LoadingScreen />}

    <NewPageLayout
      headerTitle={t(`${i18n_prefix}header`)}
      headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
      footer={showFooter ? <div className={styles.footer}>{t(`${i18n_prefix}footer`)}</div> : undefined}
    >
      <>
        {appMode === AppMode.SPECIALIST && <>
          {extendedLoading ? <SkeletonTabs count={2} className="mgb-6" />

            : <Tabs
              list={credits?.content.tabs.map((item) => ({
                text:        item.name,
                id:          item.id,
                is_selected: item.is_selected,
              })) ?? []}
              currentTab={currentTab}
              onSetCurrentTab={(value) => handleSetCurrentTabClick(value as number)}
              className={styles.tabs}
            />
          }
        </>}

        {credits?.content.credits && !credits?.content.credits.length && !extendedLoading
          && <div className={styles.empty_credits}>
            <div className={styles.empty_icon}>add_to_cart</div>

            <div className={styles.text_block}>
              <div className={styles.text_block_header}>{t(`${i18n_prefix}plug.title`)}</div>

              <div className={styles.text_block_description}>{t(`${i18n_prefix}plug.subtitle`)}</div>
            </div>

            {appMode === AppMode.SPECIALIST && <Button
              isRounded
              text={t(`${i18n_prefix}plug.button`)}
              onClick={handleBuyTariffClick}
            />}
          </div>}

        {extendedLoading ? <SkeletonLayout height={148} />

          : credits?.content.credits.map((credit) =>
            <div className="mgb-6" key={credit.id}>
              <SpecialistPaymentInformationCard
                type="credit"
                title={credit.title}
                subtitle={credit.subtitle}
                description={credit.description}
                color={credit.color}
                buttons={credit.buttons}
                onClick={handleTabButtonClick}
              />
            </div>)}
      </>
    </NewPageLayout>
  </>
};

export default Credits;