import React                                  from "react";
import {createPortal}                         from "react-dom";
import {useTranslation}                       from "react-i18next";
import {Icon, Button, NewList}                from "shared/v12ui";
import {NativeActions}                        from "shared/model";
import {useNativeHandler, setActionForNative} from "shared/lib";
import {NewPageLayout}                        from "../../../entities";
import styles                                 from "./styles.module.scss";


interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const i18n_prefix = "page.state.auto_renewal."

function AutoRenewalInfo({isOpen, onClose}: Props) {
  const {t} = useTranslation();

  const handleBackClick = () => onClose();

  const listData = [
    {id: 1, icon: "terminal", text: `${t(`${i18n_prefix}list.row_1`)}`},
    {id: 2, icon: "history", text: `${t(`${i18n_prefix}list.row_2`)}`},
    {id: 3, icon: "terminal", text: `${t(`${i18n_prefix}list.row_3`)}`},
  ];

  React.useEffect(() => {
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist other",
      properties: {
        "autorenewal": "kak eto rabotaet otrkril",
      },
    });
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return handleBackClick();
  });

  return <>
    {isOpen && createPortal(<div className={styles.wrapper}>
      <NewPageLayout
        headerTitle={t(`${i18n_prefix}title`)}
        headerRightIcon={<Icon onClick={onClose}>close</Icon>}
        footer={<Button text={t(`${i18n_prefix}button`)} onClick={handleBackClick} />}
      >
        <div className={styles.content}>
          <div className={styles.image}>
            <img src={"/illustrations/speacial_day_promo.svg"} alt="illustration" />
          </div>

          {listData.map((item, index) =>
            <NewList
              hasSpace={false}
              key={item.id ?? index}
              title={item.text}
              icon={item.icon}
            />)}
        </div>
      </NewPageLayout>
    </div>, document.body)}
  </>
}

export default React.memo(AutoRenewalInfo);