import React                                    from "react";
import {useNavigate}                            from "react-router-dom";
import {
  TariffSubscription,
  useGetSubscribeDisableInfoMutation,
  useSubscribeInitMutation,
}                                               from "services/specialists/tariffs";
import {NewList}                                from "shared/v12ui";
import {Toggle}                                 from "shared/ui";
import {useOpenPayments}                        from "shared/lib";
import {useAppDispatch}                         from "store";
import {PurchaseType, setAutoRenewalCancelInfo} from "store/features";
import {useTranslation}                         from "react-i18next";
import {AutoRenewalInfo}                        from "../../pages/state/AutoRenewalInfo";


interface Props {
  subscription?: TariffSubscription;
  isLoading: boolean;
  setIsOpen?: (value: boolean) => void;
}

export default function SubscriptionCard({subscription, isLoading, setIsOpen}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [subscribeInit, {
    data:      subscribeInitResponse,
    isLoading: subscribeInitIsLoading,
  }] = useSubscribeInitMutation();
  const [getSubscribeDisableInfo, {data: subscribeDisableInfoResponse}] = useGetSubscribeDisableInfoMutation();

  const [autoRenewalInfoIsOpen, setAutoRenewalInfoIsOpen] = React.useState(false);

  const handleOpenRenewalInfo = () => {
    setAutoRenewalInfoIsOpen(true);
    setIsOpen && setIsOpen(true);
  }

  const handleCloseRenewalInfo = () => {
    setAutoRenewalInfoIsOpen(false);
    setIsOpen && setIsOpen(false);
  }

  const handleOnSubscribeClick = () => {
    if (subscription && subscription.tariff_id)
      subscribeInit({id: subscription.tariff_id, is_now: false});
  }

  const handleToggleAutoRenewal = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleOnSubscribeClick();
    } else {
      if (subscription && subscription.subscribe_id) {
        getSubscribeDisableInfo(subscription.subscribe_id);
      }
    }
  }

  React.useEffect(() => {
    if (subscribeDisableInfoResponse && subscribeDisableInfoResponse.content && subscription?.subscribe_id) {
      dispatch(setAutoRenewalCancelInfo({
        ...subscribeDisableInfoResponse.content,
        id: subscription?.subscribe_id,
      }));
      navigate("/state/auto-renewal-cancel");
    }
  }, [dispatch, subscribeDisableInfoResponse, subscription, navigate]);

  useOpenPayments({
    paymentId: subscribeInitResponse?.content.payment_id,
    type:      PurchaseType.AUTO_RENEWAL,
  });

  return <>
    {autoRenewalInfoIsOpen && <AutoRenewalInfo isOpen={autoRenewalInfoIsOpen} onClose={handleCloseRenewalInfo} />}
    <NewList
      title={subscription?.title ?? ""}
      subtitle={subscription?.subtitle}
      link={{name: t("common.how_it_works")}}
      onLinkClick={handleOpenRenewalInfo}
      labels={subscription?.labels}
      disabled={subscription?.is_disabled}
      controlIcon={<Toggle
        checked={subscription?.is_checked ?? false}
        disabled={isLoading || subscribeInitIsLoading}
        onChange={handleToggleAutoRenewal}
      />}
    />
  </>
};