import React            from "react";
import {StepIndicator}  from "../stepIndicator";
import cn               from "classnames";
import styles           from "./PageHeader.module.scss";
import {SkeletonLayout} from "../../../entities/skeleton";


interface Props {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  secondRightIcon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  step?: number;
  hasShadow?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
}

function PageHeader({
  step,
  leftIcon,
  rightIcon,
  secondRightIcon,
  title,
  subtitle,
  hasShadow = false,
  isLoading = false,
  children,
}: Props) {
  return <>
    <div className={cn(styles.layout,
      {[styles.has_shadow]: hasShadow && !step},
      {[styles.has_children]: !!children},
    )}>
      <div className={cn(styles.header_info_holder, {[styles.has_second_icon]: !!secondRightIcon})}>
        <div className={styles.iconHolder}>{leftIcon}</div>
        <div className={styles.content}>
          {isLoading ? <div className={styles.loading_wrapper}>
              <SkeletonLayout height={22} width={200} borderRadius={12} theme="dark" />
            </div>
            : <span className={styles.title}>{title}</span>}
          {subtitle && !isLoading && <span className={styles.subtitle}>{subtitle}</span>}
        </div>
        <div className={styles.iconHolder}>{rightIcon}</div>
        {secondRightIcon && <div className={styles.iconHolder}>{secondRightIcon}</div>}
      </div>
      {children && <div className={cn(styles.children_holder)}>{children}</div>}
    </div>
    {step &&
      <div className={cn(styles.step_holder, {[styles.has_shadow]: hasShadow})}><StepIndicator step={step} /></div>}
  </>
}

export default React.memo(PageHeader);