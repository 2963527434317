import React                                   from "react";
import {useNavigate}                           from "react-router-dom";
import {useTranslation}                        from "react-i18next";
import {SkeletonLayout}                        from "entities/skeleton";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {Nullable}                              from "shared/model";
import {ClientProfileItem}                     from "../../api/clientProfileApi";
import {ReviewRating}                          from "entities/reviews/ReviewRating";
import {ReviewItem}                            from "entities/reviews/ReviewItem";
import styles                                  from "./ReviewsList.module.scss";


interface Props {
  id: Nullable<number>;
  loading: boolean;
  clientProfile?: ClientProfileItem;
}

const i18n_prefix = "page.client.profile."

function ReviewsList({id, loading, clientProfile}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const stars = [
    clientProfile?.reviews_overview?.star5 ?? 0,
    clientProfile?.reviews_overview?.star4 ?? 0,
    clientProfile?.reviews_overview?.star3 ?? 0,
    clientProfile?.reviews_overview?.star2 ?? 0,
    clientProfile?.reviews_overview?.star1 ?? 0,
  ];

  const handleShowAllReviewsClick = () => navigate(`/client-reviews/${id}`);

  return <div className={styles.content_wrapper}>
    <div className={styles.content}>
      {loading ? <>
          <SkeletonLayout height={22} width={130} borderRadius={6} theme="dark" />
          <SkeletonLayout height={102} />
          <div className={styles.skeleton_reviews}>
            <SkeletonLayout height={179} />
            <SkeletonLayout height={46} theme="dark" />
          </div>
        </>
        : <>
          <span className={styles.header}>{t(`${i18n_prefix}title`)}</span>
          <ReviewRating
            rate={clientProfile?.client.rate}
            nmbReviews={clientProfile?.reviews_overview.nmb_reviews}
            stars={stars}
          />
          {clientProfile?.reviews?.slice(0, 2).map((review) => {
            return <ReviewItem
              key={review.id}
              name={review.name ?? ""}
              date={review.created_at ?? ""}
              rating={review.rate ?? 0}
              review_text={review.text ?? ""}
              media={review.media ?? []}
              price={review.sum}
              isApproved={review.is_approved ?? false}
              isViewMode={false}
            />
          })}
        </>}

      {!loading && !clientProfile?.reviews &&
        <span className={styles.empty_reviews}>{t(`${i18n_prefix}empty_reviews`)}</span>}

      {!loading && (
        <Button
          text={t(`${i18n_prefix}show_all`)}
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleShowAllReviewsClick}
        />
      )}
    </div>
  </div>
}

export default React.memo(ReviewsList);