import {TFunction} from "i18next";

export const dayList = (t: TFunction<"translation", undefined>, index: number) => {
  return t(`page.order.day_list.${index}`);
};

export const orderTimeList = [
  {id: "07:00 - 09:00", text: "07:00 - 09:00"},
  {id: "09:00 - 11:00", text: "09:00 - 11:00"},
  {id: "11:00 - 13:00", text: "11:00 - 13:00"},
  {id: "13:00 - 15:00", text: "13:00 - 15:00"},
  {id: "15:00 - 17:00", text: "15:00 - 17:00"},
  {id: "17:00 - 19:00", text: "17:00 - 19:00"},
  {id: "19:00 - 21:00", text: "19:00 - 21:00"},
  {id: "21:00 - 23:00", text: "21:00 - 23:00"},
];