import React           from "react";
import {Icon, NewList} from "shared/v12ui";
import {NewPageLayout} from "../newPageLayout";
import styles          from "./styles.module.scss";


interface Props {
  title: string;
  subtitle?: string;
  onClose: () => void;
  img: string;
  list: { id: number, icon: string, iconColor?: string, text: string }[];
  children?: React.ReactNode;
}

export default function StatePageLayout({title, subtitle, img, children, list, onClose}: Props) {
  return <NewPageLayout
    headerTitle={title}
    headerSubtitle={subtitle}
    headerRightIcon={<Icon className="text black" onClick={onClose}>close</Icon>}
    footer={children}
  >
    <div className={styles.content}>
      <div className={styles.image}>
        <img src={img} alt="illustration" />
      </div>

      {list.map((item, index) => {
          return <NewList
            hasSpace={false}
            key={item.id ?? index}
            icon={<Icon iconColor={item.iconColor}>{item.icon}</Icon>}
            title={item.text}
          />
        },
      )}
    </div>
  </NewPageLayout>
}