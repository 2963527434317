import React                                         from "react";
import {useTranslation}                              from "react-i18next";
import {Button, Icon, NewButtonColor, NewButtonType} from "shared/v12ui";
import {setActionForNative, useToggleState}          from "shared/lib";
import {NativeActions}                               from "shared/model";
import {InApp}                                       from "shared/ui";
import {useFetchCloseOrderInfoMutation}              from "../../api";
import styles                                        from "./CloseOrderForm.module.scss";


interface Props {
  description?: string;
}

export default function CloseOrderForm({description}: Props) {
  const {t} = useTranslation();

  const [getCloseOrderInfo, {data: ticketBonus, isError}] = useFetchCloseOrderInfoMutation();

  const [closeInApp, toggleCloseInApp] = useToggleState();

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleCloseOrderButtonClick = () => {
    if (description && description?.trim() !== "") {
      return getCloseOrderInfo();
    } else {
      return handleOnCloseClick();
    }
  }

  React.useEffect(() => {
    if (ticketBonus) {
      toggleCloseInApp();
    }
  }, [ticketBonus]);

  React.useEffect(() => {
    if (isError) {
      handleOnCloseClick();
    }
  }, [isError])

  const descriptionWithBonus = ticketBonus?.first_ticket_bonus
    ? <div className={styles.description}>
      {t("page.order.close_order.subtext_with_bonus.first_part")}
      <span className={styles.sum}>
        {t("page.order.close_order.subtext_with_bonus.second_part", {bonus: ticketBonus?.first_ticket_bonus})}
      </span>
      {t("page.order.close_order.subtext_with_bonus.third_part")}
    </div>
    : t("page.order.close_order.subtext");

  return <>
    <Icon onClick={handleCloseOrderButtonClick} iconColor={"#2B2B2B"}>close</Icon>

    <InApp
      image="/illustrations/delete-4.svg"
      headerText={t("page.order.close_order.title")}
      headerSubtext={descriptionWithBonus}
      open={closeInApp}
      onClose={toggleCloseInApp}
    >
      <div className="inApp_buttons">
        <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          text={t("common.exit")}
          onClick={handleOnCloseClick}
        />
        <Button
          text={t("common.continue")}
          onClick={toggleCloseInApp}
          hasSpace
        />
      </div>
    </InApp>
  </>
};