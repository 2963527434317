import {setActionForNative} from "../../utils";
import {NativeActions}      from "../../../model";
import {useNativeHandler}   from "../useNativeHandler";

export default function useBackTap(conditional?: boolean) {
  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (conditional) return;
    handleBackClick();
  });

  return {handleBackClick}
};