export const upload_media = {
  title:    "Фото және видео",
  transfer: {
    title: "Файл қосу",
    count: "{{maxCount}} ішінен {{currentCount}}",
    list:  {
      make_photo:   "Фото түсіру",
      record_video: "Видео жазу",
      open_gallery: "Галереядан таңдау",
    },
  },
}
