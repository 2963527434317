import * as React                                 from "react";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import Cropper, {ReactCropperElement}             from "react-cropper";
import {CloseRegistrationInApp}                   from "components/CloseRegistrationInApp";
import {PageHeader}                               from "shared/ui";
import {imageUrl}                                 from "shared/lib";
import {Nullable}                                 from "shared/model";
import {NewList}                                  from "shared/v12ui";
import {UploadComponent}                          from "components/UploadComponent";
import {useTranslation}                           from "react-i18next";
import {ImageLoadError, ImageLoading}             from "entities/registration";
import styles                                     from "./styles.module.scss";
import "cropperjs/dist/cropper.css";


const cropperStyle = {
  position: "fixed",
  top:      "calc(env(safe-area-inset-top) + 7rem)",
  height:   "calc(100vh - 20rem)",
  width:    "100%",
  maxWidth: "100%",
}

const MIN = 0.5;
const MAX = 3;
const STEP = .1;

const CropperComponent = ({onNext, setStep, digest, setDigest}: {
  onNext: () => void,
  digest: Nullable<string>,
  setStep: (value: string) => void,
  setDigest: React.Dispatch<React.SetStateAction<Nullable<string>>>,
}) => {
  const {t} = useTranslation();
  const cropperRef = useRef<ReactCropperElement>(null);
  const rangeRef = useRef<HTMLInputElement>(null);
  const [cropData, setCropData] = useState<any>("");
  const [failUploadPhotoInApp, setFailUploadPhotoInApp] = useState(false);

  const [zoom, setZoom] = useState<any>(1);

  const onCrop = () => {
    setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
  };

  const [loading, setLoading] = useState(true);

  const handleRotateLeft = () => {
    if (cropperRef && cropperRef.current) {
      cropperRef.current.cropper.rotate(-90);
      cropperRef.current?.cropper.zoomTo(1);
      setZoom(1)
    }
  }

  const handleRotateRight = () => {
    if (cropperRef && cropperRef.current) {
      cropperRef.current.cropper.rotate(90);
      cropperRef.current?.cropper.zoomTo(1);
      setZoom(1)
    }
  }

  const handleChangeZoom = (event: ChangeEvent<HTMLInputElement>) => {
    setZoom(event.target.value);
    cropperRef.current?.cropper.zoomTo(event.target.value as any);
  }

  const computeGradient = (value: number) => {
    const percentage = ((value - MIN) / (MAX - MIN)) * 100;
    return `linear-gradient(to right, #0A84FF 0%, #0A84FF ${percentage}%, #fff ${percentage}%, white 100%)`;
  }

  const handleZoom = (event: any) => {
    setZoom(event.detail.ratio);
  }

  const [currentImage, setCurrentImage] = useState<string>("");

  const handleBackClick = () => {
    setFailUploadPhotoInApp(false);
    setStep("1");
  }

  useEffect(() => {
    let count = 1;
    let interval: number;

    const handleGetImage = () => {
      if (digest) {
        const url = imageUrl(digest);
        setCurrentImage(url);
      }
    }

    if (loading && !currentImage) {
      interval = window.setInterval(() => {
        if (count === 6) {
          window.clearInterval(interval);
          setFailUploadPhotoInApp(true);
          return;
        }
        count++;
        handleGetImage();
      }, 2000);
    }

    return () => {
      window.clearInterval(interval);
    };
  }, [loading, currentImage, digest]);

  return <div className={styles.wrapper}>
    {!loading && <div className={styles.header}>
      <PageHeader title={t("component.cropper.header.title")} rightIcon={<CloseRegistrationInApp />} />
    </div>}

    <Cropper
      src={currentImage}
      onError={() => {
        setLoading(true);
        setCurrentImage("");
      }}
      guides={false}
      ready={() => setLoading(false)}
      background={false}
      ref={cropperRef}
      autoCropArea={0.9}
      zoomTo={1}
      center
      cropBoxMovable={false}
      width={"100%"}
      rotatable={true}
      height={400}
      zoomable={true}
      zoomOnWheel={false}
      zoomOnTouch={true}
      cropBoxResizable={false}
      checkOrientation={false}
      checkCrossOrigin={false}
      crossOrigin="anonymous"
      dragMode="move"
      style={cropperStyle as any}
      aspectRatio={16 / 9}
      preview=".img-preview"
      viewMode={1}
      responsive={true}
      zoom={handleZoom}
      className={loading ? styles.img_loading : ""}
    />

    {loading && !failUploadPhotoInApp && <div className={styles.loading_wrapper}><ImageLoading /></div>}

    {!loading && <div className={styles.settings}>
      <button onClick={handleRotateLeft} className={styles.rotate_button}>
        <div>rotate_left</div>
      </button>

      <div className={styles.settings_input_wrapper}>
        <span>{t("component.cropper.scale")}</span>

        <input
          style={{background: computeGradient(zoom)}}
          ref={rangeRef}
          id="input_range"
          value={zoom}
          type="range"
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={handleChangeZoom}
        />
      </div>

      <button onClick={handleRotateRight} className={styles.rotate_button}>
        <div>rotate_right</div>
      </button>
    </div>}

    {!loading && <div className={styles.inApp_wrapper}>
      <div className={styles.inApp}>
        <NewList hasSpace={false} title={t("component.cropper.text")} />
        <UploadComponent
          isLoading={loading}
          setStep={setStep}
          setDigest={setDigest}
          onCropData={onCrop}
          onNext={onNext}
          digest={cropData}
        />
      </div>
    </div>}

    {failUploadPhotoInApp && <ImageLoadError onClick={handleBackClick} />}
  </div>
}

export default CropperComponent;