import React            from "react";
import {NewButtonType}  from "./model/newButtonType";
import {NewButtonColor} from "./model/newButtonColor";
import ButtonLoading    from "./ui/ButtonLoading";
import cn               from "classnames";
import styles           from "./Button.module.scss";


interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasSpace?: boolean;
  buttonType?: NewButtonType;
  buttonColor?: NewButtonColor;
  icon?: React.ReactNode;
  additionalIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  additionalText?: string;
  text: string;
  secondLine?: string;
  loading?: boolean;
  isRounded?: boolean;
  children?: React.ReactNode;
  onAdditionalButtonClick?: () => void;
}

type ButtonContextType = {
  additionalIcon?: React.ReactNode;
  onAdditionalButtonClick?: () => void;
  additionalText?: string;
}

const ButtonContext = React.createContext<ButtonContextType | undefined>(undefined);

function useButtonContext() {
  const context = React.useContext(ButtonContext);
  if (!context) {
    throw new Error("useButtonContext must be used within a ButtonContext");
  }
  return context;
}

function Button({
  buttonType = NewButtonType.DEFAULT,
  buttonColor = NewButtonColor.BLACK,
  type = "button",
  hasSpace = false,
  icon,
  rightIcon,
  additionalIcon,
  additionalText,
  text,
  secondLine,
  loading,
  onClick,
  onAdditionalButtonClick,
  children,
  isRounded = false,
  ...rest
}: Props) {

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    if (rest.disabled) return;
    onClick && onClick(event);
  }

  return <ButtonContext.Provider value={{additionalIcon, additionalText, onAdditionalButtonClick}}>
    <div className={cn(styles.wrapper, {[styles.has_space]: hasSpace}, {[styles.rounded]: isRounded})}>
      {isRounded ? <button
          onClick={handleButtonClick}
          className={cn(styles.button_rounded,
            {[`${styles[buttonType]}`]: buttonType},
            {[`${styles[buttonColor]}`]: buttonColor},
            {[styles.is_loading]: loading},
          )}
          {...rest}
        >
          {loading ? <ButtonLoading isRounded buttonType={buttonType} buttonColor={buttonColor} /> : text}
        </button>
        : <>
          {children}
          <button
            type={type}
            onClick={handleButtonClick}
            className={cn(styles.button,
              {[`${styles[buttonType]}`]: buttonType},
              {[`${styles[buttonColor]}`]: buttonColor},
              {[styles.is_loading]: loading},
            )}
            {...rest}
          >
            {loading && <ButtonLoading buttonType={buttonType} buttonColor={buttonColor} />}
            <div className={cn(styles.children_holder, {[styles.is_loading]: loading})}>
              <div>{icon}</div>
              <div className={cn(styles.text_wrapper, {[styles.second_line]: !!secondLine})}>
                <span>{text}</span>
                {secondLine && <span>{secondLine}</span>}
              </div>
              <div>{rightIcon}</div>
            </div>
          </button>
        </>
      }
    </div>
  </ButtonContext.Provider>
}

Button.AdditionalButton = function AdditionalButton() {
  const {onAdditionalButtonClick, additionalIcon, additionalText} = useButtonContext();
  return (
    <button type="button" onClick={onAdditionalButtonClick} className={styles.additional_button}>
      {additionalIcon ? <div className={styles.additional_icon}>{additionalIcon}</div>
        : <div className={styles.additional_text}>{additionalText}</div>}
    </button>
  )
}

export default Button;