import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {useNavigate}                           from "react-router-dom";
import {InApp}                                 from "shared/ui";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import styles                                  from "./styles.module.scss";


interface Props {
  open: boolean;
  onClose: () => void;
}

export default function WrongPhoneNumberInApp({open, onClose}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleBackSelectRegistration = () => navigate("/registration");

  return <InApp
    image="/illustrations/searching-6.svg"
    headerText={t("in_app.wrong_number.title")}
    headerSubtext={t("in_app.wrong_number.description")}
    open={open}
    onClose={onClose}
  >
    <div className={styles.inApp_buttons}>
      <Button
        buttonType={NewButtonType.WRAPPED}
        buttonColor={NewButtonColor.GRAY}
        text={t("in_app.wrong_number.button.try_again")}
        onClick={onClose}
      />

      <Button
        hasSpace
        text={t("in_app.wrong_number.button.cancel")}
        onClick={handleBackSelectRegistration}
      />
    </div>
  </InApp>
}