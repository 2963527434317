import React            from "react";
import {SkeletonLayout} from "entities/skeleton";
import {checkProgress}  from "../../lib";
import styles           from "./ReferralIndicator.module.scss";


interface Props {
  loading: boolean;
  bar?: {
    current: number;
    total: number;
    title: string;
    description: string;
  }
}

function ReferralIndicator({loading, bar}: Props) {
  return <>
    {loading
      ? <SkeletonLayout height={80} />
      : <div className={styles.invite_count_wrapper}>
        <span className={styles.invite_count_header}>{bar?.title ?? ""}</span>

        <span className={styles.indicator_container}>
        <span
          className={styles.indicator_bar}
          style={{width: `${checkProgress(bar?.total, bar?.current)}%`}}
        />
      </span>

        <span className={styles.invite_count_description}>{bar?.description}</span>
      </div>}
  </>
}

export default React.memo(ReferralIndicator);