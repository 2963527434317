import React, {MouseEvent, useCallback, useEffect, useRef} from "react";
import {useAppDispatch, useAppSelector}                    from "store";
import {removeNotification, selectNotificationList}        from "store/features";
import {Icon, IconType}                                    from "shared/v12ui";
import cn                                                  from "classnames";
import styles                                              from "./styles.module.scss";
import {createPortal}                                      from "react-dom";


interface Props {
  bottomPosition?: number;
  onRemoveToastClick?: (id?: string) => void;
}

function Toast({
  bottomPosition, onRemoveToastClick = () => {
  },
}: Props) {
  const dispatch = useAppDispatch();
  const timeoutListener = useRef<NodeJS.Timeout>();
  const notificationMessages = useAppSelector(selectNotificationList);

  const handleRemoveNotification = useCallback(() => dispatch(removeNotification()), [dispatch]);

  const handleRemoveToastClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onRemoveToastClick(notificationMessages?.id);
    handleRemoveNotification();
  }

  useEffect(() => {
    if (notificationMessages) {
      timeoutListener.current = setTimeout(() => {
        handleRemoveNotification();
      }, 2750);
    } else {
      clearTimeout(timeoutListener.current);
    }

    return () => {
      clearTimeout(timeoutListener.current);
    }
  }, [handleRemoveNotification, notificationMessages]);

  return <>
    {notificationMessages &&
      createPortal(<div
        onClick={handleRemoveNotification}
        style={{bottom: `${bottomPosition}px`}}
        className={cn(styles.wrapper, {[styles.remove_type]: notificationMessages.type === "remove"})}
      >
        {notificationMessages?.icon && <Icon size={16} className="mgr-2">{notificationMessages?.icon ?? "info"}</Icon>}

        {notificationMessages.type === "remove"
          && <Icon type={IconType.ROUNDED} isRemoveToastIcon iconColor={"#FFFFFF"}>delete</Icon>}

        <div className={cn(styles.text, {[styles.remove_type]: notificationMessages.type === "remove"})}>
          {notificationMessages.type === "remove"
            ? notificationMessages.text.split(".").map((item, index) => {
              if (index === 1) {
                return <span
                  key={index}
                  onClick={handleRemoveToastClick}
                  style={{textDecoration: "underline", marginLeft: ".2rem"}}
                >
                  {item}
                </span>
              }
              return <React.Fragment key={index}>{`${item}.`}</React.Fragment>
            })
            : notificationMessages.text
          }
        </div>

        <Icon className={styles.close_icon}>close</Icon>
      </div>, document.body)}
  </>
}

export default React.memo(Toast);