import React                                                from "react";
import {useTranslation}                                     from "react-i18next";
import {Control, useFieldArray}                             from "react-hook-form"
import {GalleryMediaItem, GalleryWrapper, Label, LabelItem} from "shared/ui";
import {CreateState, ImageFromNativeItem}                   from "pages/order/model/createState";
import {addNotification}                                    from "store/features";
import {useAppDispatch}                                     from "store";
import {useRemoveMediaMutation}                             from "./api";
import TransferringMedia                                    from "./ui/TransferringMedia";
import styles                                               from "./UploadMedia.module.scss";


interface Props {
  control: Control<CreateState>;
  labels?: LabelItem[];
  description?: string;
  title?: string;
}

function UploadMedia({title, description, control, labels}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const timeoutListener = React.useRef<NodeJS.Timeout | null>(null);

  const [removeMedia] = useRemoveMediaMutation();

  const {fields, remove, append} = useFieldArray({control, name: "media"});

  const [openSelect, setOpenSelect] = React.useState(false);
  const [removedMedia, setRemovedMedia] = React.useState<ImageFromNativeItem[]>([]);

  const onRemove = (media: ImageFromNativeItem, index: number) => {
    timeoutListener.current = setTimeout(() => {
      removeMedia({type: media.type === "video" ? "video" : "image", digest: media.digest as string});
      remove(index);
      setRemovedMedia(prev => prev.filter((item) => item.digest !== media.digest));
      if (timeoutListener.current) {
        clearTimeout(timeoutListener.current);
        timeoutListener.current = null;
      }
    }, 2750);
  }

  const handleRemoveMedia = (media: ImageFromNativeItem, index: number) => {
    setRemovedMedia(prev => ([...prev, {...media, index}]));
    dispatch(addNotification({text: "Файл удалён. Отмена", type: "remove", id: media.digest}));

    if (timeoutListener.current === null) {
      onRemove(media, index);
    } else {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
      const objectInProcess = removedMedia[0];
      const removedIndex = fields.findIndex((item) => item.digest === objectInProcess.digest);
      remove(removedIndex);
      onRemove(media, index);
      setRemovedMedia(prev => prev.filter((item) => item.digest !== objectInProcess.digest));
    }
  }

  const handleRemoveToastClick = (id?: string) => {
    if (timeoutListener.current) {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
    }
    const removeObject = removedMedia.find((item) => item.digest === id);

    if (removeObject) {
      setRemovedMedia((prev) => prev.filter((item) => item !== removeObject));
    }
  };

  const handleToggleSelect = () => {
    setOpenSelect(prev => !prev);

    if (timeoutListener.current) {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;

      const objectInProcess = removedMedia[0];
      const removedIndex = fields.findIndex((item) => item.digest === objectInProcess.digest);
      remove(removedIndex);
      setRemovedMedia([]);
    }
  }

  return <div className={styles.layout}>
    <div className={styles.title_holder}>
      <span className={styles.title}>{title ?? t("features.upload_media.title")}</span>
      {labels && <Label labels={labels} />}
    </div>

    <GalleryWrapper>
      <ul className={styles.media_wrapper}>
        {fields.length < 5 &&
          <li>
            <button type="button" className={styles.upload_button} onClick={handleToggleSelect}>
              <span className={styles.add_icon}></span>
            </button>
          </li>}

        {!!fields.length && fields
          .filter((item) => !removedMedia.some((removed) => item.digest === removed.digest))
          .map((field, fieldIndex) => {
            return <li key={field.id}>
              <GalleryMediaItem size={92} digest={field.digest as string} type={field.type} />
              <button
                type="button"
                onClick={() => handleRemoveMedia(field, fieldIndex)}
                className={styles.remove_media}
              >
                delete
              </button>
            </li>
          })}
      </ul>
    </GalleryWrapper>

    <TransferringMedia
      limit={fields.length}
      control={control}
      open={openSelect}
      onClose={() => setOpenSelect(false)}
      onAppendMedia={append}
      onRemoveMediaToastClick={handleRemoveToastClick}
    />

    <span className={styles.description}>{description}</span>
  </div>
}

export default UploadMedia;