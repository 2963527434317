import React                       from "react";
import {useFetchOrderDetailsQuery} from "../api";
import {useSetHeaders}             from "shared/lib";
import {useAppDispatch}            from "store";
import {setCurrentCity}            from "store/features";


export function useFetchOrderDetails() {
  const dispatch = useAppDispatch();
  const {isSuccess, ticketId, workId, isDemo} = useSetHeaders();
  const {data: orderDetails} = useFetchOrderDetailsQuery({
    ticket_id: ticketId ?? undefined,
    work_id:   workId ?? undefined,
  }, {skip: !isSuccess});

  React.useEffect(() => {
    if (orderDetails) {
      dispatch(setCurrentCity(orderDetails.city));
    }
  }, [orderDetails]);

  return {orderDetails, city: orderDetails?.city, ticketId, workId, isDemo};
}