import React                 from "react";
import {useTranslation}      from "react-i18next";
import {LabelItem, Textarea} from "shared/ui";
import {Tabs}                from "shared/v12ui";
import {useToggleState}      from "shared/lib";
import {UploadMedia}         from "features/global/uploadMedia";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
}                            from "react-hook-form";
import {PickOrderDate}       from "features/order/pickDate";
import {CreateState}         from "../../model/createState";
import {DateListType}        from "../../model/dateListType";
import {Nullable}            from "shared/model";


interface Props {
  watch: UseFormWatch<CreateState>;
  errors: FieldErrors<CreateState>;
  register: UseFormRegister<CreateState>;
  labels?: LabelItem[];
  control: Control<CreateState>;
  setValue: UseFormSetValue<CreateState>;
  dateList: DateListType[];
  clearErrors: UseFormClearErrors<CreateState>;
  onSetDateList: React.Dispatch<React.SetStateAction<DateListType[]>>;
}

function FirstStepOrder({
  watch,
  errors,
  dateList,
  control,
  register,
  labels,
  setValue,
  clearErrors,
  onSetDateList,
}: Props) {
  const {t} = useTranslation();

  const [selectTime, toggleSelectTime] = useToggleState();

  const handleSetDateValue = (value?: Nullable<string | number>) => {
    if (!value) return;
    if (value === "selectDate") {
      toggleSelectTime();
    }
    if (value === "fast") {
      setValue("isFast", true);
    } else {
      setValue("date", value as string);
      setValue("isFast", false);
    }
    setValue("dateTab", value as string);
  }

  return <>
    <Textarea
      isNew
      isRequired
      hasSpace
      hasFocus
      errorMessage={errors.description?.message}
      hasSymbolCounter={false}
      placeholder={t("page.order.placeholders.description")}
      label={t("page.order.labels.description")}
      hint={t("page.order.hints.description")}
      {...register("description", {
        onChange: () => {
          clearErrors("description");
        },
      })}
    />

    <UploadMedia
      description={t("page.order.hints.media")}
      control={control}
      labels={labels}
    />

    <Controller
      control={control}
      name="dateTab"
      render={({field}) => {
        return <Tabs
          header={t("page.order.labels.date_and_time")}
          list={dateList}
          currentTab={field.value}
          onSetCurrentTab={handleSetDateValue}
        />
      }}
    />

    {selectTime && <PickOrderDate
      watch={watch}
      control={control}
      setValue={setValue}
      open={selectTime}
      onClose={toggleSelectTime}
      onUpdateDateList={onSetDateList}
    />}
  </>
}

export default FirstStepOrder;