import React                   from "react";
import {useParams}             from "react-router-dom";
import {NewPageLayout}         from "entities/general";
import {Icon}                  from "shared/v12ui";
import {useBackTap}            from "shared/lib";
import {useAppSelector}        from "store";
import {selectGalleryOpen}     from "store/features";
import {useFetchClientProfile} from "./model/useFetchClientProfile";
import ReviewsList             from "./ui/reviews/ReviewsList";
import InfoBlock               from "./ui/info/InfoBlock";


export default function ClientProfile() {
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : null;
  const galleryIsOpen = useAppSelector(selectGalleryOpen);

  const {clientProfile, extendedLoading} = useFetchClientProfile(id);
  const {handleBackClick} = useBackTap(galleryIsOpen);

  return <NewPageLayout headerLeftIcon={
    <Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>
  }>
    <InfoBlock loading={extendedLoading} clientProfile={clientProfile} />
    <ReviewsList id={id} loading={extendedLoading} clientProfile={clientProfile} />
  </NewPageLayout>
};