import React            from "react";
import {useTranslation} from "react-i18next";
import {Accordion}      from "shared/ui";
import {SkeletonLayout} from "entities/skeleton";
import styles           from "./QuestionsList.module.scss";


interface Props {
  loading: boolean;
  faqs?: {
    question: string;
    answer: string;
  }[];
}

function QuestionsList({loading, faqs}: Props) {
  const {t} = useTranslation();

  return <>
    {loading
      ? <div className={styles.questions_wrapper}>
        <SkeletonLayout height={22} borderRadius={6} />

        <div className="mgt-3">
          <SkeletonLayout height={56} />
        </div>
      </div>
      : <>
        {!faqs?.length
          ? null
          : <div className={styles.questions_wrapper}>
            <span className={styles.content_header}>{t(`page.customers.invitation.questions`)}</span>

            {faqs.map((faq, index) => {
              return <Accordion
                key={index}
                title={faq.question}
                description={faq.answer}
              />
            })}
          </div>}
      </>}
  </>
}

export default React.memo(QuestionsList);