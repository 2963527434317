import React                                  from "react";
import {NativeActions, Nullable}              from "shared/model";
import {setActionForNative, useNativeHandler} from "shared/lib";
import {useAppDispatch}                       from "store";
import {setGeolocationPermission}             from "../../model/permissionsSlice";

interface Props {
  hasGeoPermission: Nullable<boolean>;
}

export default function useCheckGeoPermission({hasGeoPermission}: Props) {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (hasGeoPermission === null) {
      setActionForNative(NativeActions.HAS_PERMISSIONS_GEO);
    }
  }, [hasGeoPermission]);

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_GEO, data => {
    return dispatch(setGeolocationPermission(data.has_permissions));
  });
};