import React, {ChangeEvent, useEffect, useState}  from "react";
import {Controller, SubmitHandler, useForm}       from "react-hook-form"
import {
  DatePicker,
  BaseInput,
  RadioInput,
  InApp,
}                                                 from "shared/ui";
import {Button}                                   from "shared/v12ui";
import {Props}                                    from "pages/Registration/ManualRegistration";
import {WithDocument}                             from "pages/Registration";
import {CloseRegistrationInApp}                   from "components/CloseRegistrationInApp";
import {
  GenderType,
  RegistrationMethodType,
  RegistrationType,
  useSendManualFormMutation,
  useSendNonResidentFormMutation,
}                                                 from "services/registration";
import {SsnExistInApp}                            from "components/SsnExistInApp";
import styles                                     from "./styles.module.scss";
import {IdentityVerification}                     from "components/IdentityVerification";
import {setActionForNative}                       from "shared/lib";
import {NativeActions}                            from "shared/model";
import {useTranslation}                           from "react-i18next";
import {useAppSelector}                           from "store";
import {selectMethodType, selectRegistrationType} from "store/features";
import {NewPageLayout}                            from "entities/general";


type Inputs = {
  first_name: string;
  last_name: string;
  ssn: string;
  gender: GenderType;
  birthDate: string;
  profile?: string;
}

const i18n_prefix = "component.manual."

function ManualRegistration({type, navSubtitle, ssnSubtitle}: Props) {
  const {t} = useTranslation();
  const methodType = useAppSelector(selectMethodType);
  const registrationType = useAppSelector(selectRegistrationType);

  const genders = [
    {id: GenderType.MALE, label: t("in_app.gender.male")},
    {id: GenderType.FEMALE, label: t("in_app.gender.female")},
  ];

  const [sendNonResidentForm, {
    data:      nonResidentData,
    isLoading: nonResidentFormLoading,
  }] = useSendNonResidentFormMutation();
  const [sendManualForm, {
    data:      manualData,
    isLoading: manualFormLoading,
  }] = useSendManualFormMutation();

  const sendForm = type === "nonResident" ? sendNonResidentForm : sendManualForm;
  const data = type === "nonResident" ? nonResidentData : manualData;
  const formLoading = type === "nonResident" ? nonResidentFormLoading : manualFormLoading;

  const [openSelectGender, setOpenGender] = useState(false);
  const [openSelectBirthDay, setOpenSelectBirthDay] = useState(false);
  const [ssnExistInApp, setSsnExistInApp] = useState(false);
  const [ssnBlockedInApp, setSsnBlockedInApp] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: {errors, isValid},
  } = useForm<Inputs>({
    defaultValues: {
      first_name: "",
      last_name:  "",
      gender:     GenderType.EMPTY,
      birthDate:  "",
      profile:    "",
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    sendForm({
      first_name: data.first_name,
      last_name:  data.last_name,
      birthday:   data.birthDate,
      gender:     data.gender,
      ssn:        data.ssn,
    });
  };

  const handleCancel = () => {
    setSsnBlockedInApp(false);
    clearErrors("profile");
    setValue("ssn", "");
  }

  const handleToggleGenderInApp = () => setOpenGender(prev => !prev);

  const handleToggleBirthDayInApp = () => setOpenSelectBirthDay(prev => !prev);

  const handleOnChangeGender = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("gender", event.target.value as GenderType);
    handleToggleGenderInApp();
  }

  const handleSetBirthDay = (value: string) => setValue("birthDate", value);

  const ssnSubtext = ssnSubtitle ?? t("page.registration.manual_registration.text");

  const handleMakeFacePhoto = () => setActionForNative(NativeActions.MAKE_FACE_PHOTO);

  useEffect(() => {
    if (data?.errors?.profile === "ssn_exists") {
      setSsnExistInApp(true);
    }
    if (data?.errors?.profile === "ssn_blocked") {
      setSsnBlockedInApp(true);
    }
  }, [data]);

  useEffect(() => {
    if (data?.errors) {
      for (const [fieldName, message] of Object.entries(data.errors)) {
        setError(fieldName as any, {
          type: "server",
          message,
        });
      }
    }
  }, [data, setError]);

  useEffect(() => {
    if (registrationType === RegistrationType.MANUAL) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist registration method opened",
        properties: {
          "registration method": registrationType,
          "registration step":   "1",
        },
      });
    }
  }, [registrationType]);

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    register("gender", {required: true});
  }, [register]);

  return <>
    {methodType === RegistrationMethodType.VERIFICATION && <WithDocument />}
    {((data?.status === true) && !hasError) && methodType !== RegistrationMethodType.VERIFICATION ? <>
        {type === "nonResident" ? <WithDocument /> : <IdentityVerification onClick={handleMakeFacePhoto} />}
      </>
      : <NewPageLayout
        headerTitle={t("page.registration.header")}
        headerSubtitle={navSubtitle}
        headerRightIcon={<CloseRegistrationInApp />}
        footer={<Button
          text={t("common.next")}
          loading={formLoading}
          disabled={!isValid || hasError}
          form="hook_form"
          type="submit"
        />}
      >
        <form id="hook_form" onSubmit={handleSubmit(onSubmit)} className={styles.content}>
          <BaseInput
            label={t(`${i18n_prefix}form.name_label`)}
            error={!!errors.first_name?.message}
            labelIcon="asterix"
            placeholder={t(`${i18n_prefix}form.name_placeholder`)}
            subtext={errors.first_name && errors.first_name.message}
            {...register("first_name", {
              required: true, onChange: () => {
                clearErrors("first_name");
              },
            })}
          />

          <BaseInput
            label={t(`${i18n_prefix}form.lastname_label`)}
            labelIcon="asterix"
            error={!!errors.last_name?.message}
            placeholder={t(`${i18n_prefix}form.lastname_placeholder`)}
            subtext={errors.last_name && errors.last_name?.message}
            {...register("last_name", {required: true, onChange: () => clearErrors("last_name")})}
          />

          <Controller
            name="gender"
            control={control}
            defaultValue={GenderType.EMPTY}
            render={({field}) => (
              <BaseInput
                label={t(`${i18n_prefix}form.gender_label`)}
                labelIcon="asterix"
                placeholder={t(`${i18n_prefix}form.gender_placeholder`)}
                rightIcon="keyboard_arrow_down"
                onReadOnlyClick={handleToggleGenderInApp}
                value={genders.find(item => item.id === field.value)?.label ?? ""}
                readOnly
              />
            )}
          />

          <Controller
            name="birthDate"
            control={control}
            defaultValue=""
            render={({field}) => (
              <BaseInput
                label={t(`${i18n_prefix}form.birth_date_label`)}
                labelIcon="asterix"
                placeholder={t(`${i18n_prefix}form.birth_date_placeholder`)}
                rightIcon="keyboard_arrow_down"
                onReadOnlyClick={handleToggleBirthDayInApp}
                value={field.value}
                {...register("birthDate", {required: true})}
                readOnly
              />
            )}
          />

          <BaseInput
            label={t(`${i18n_prefix}form.ssn_label`)}
            labelIcon="asterix"
            placeholder={t(`${i18n_prefix}form.ssn_placeholder`)}
            error={!!errors.ssn?.message}
            maxLength={12}
            type="tel"
            subtext={errors.ssn ? errors.ssn?.message : ssnSubtext}
            {...register("ssn", {required: true, minLength: 12, maxLength: 12, onChange: () => clearErrors("ssn")})}
          />
        </form>
      </NewPageLayout>


    }

    <SsnExistInApp
      ssn={watch("ssn")}
      open={ssnExistInApp}
      onClose={() => {
        setSsnExistInApp(false);
        clearErrors("profile");
        setValue("ssn", "");
      }}
    />

    <InApp
      image={"/illustrations/no-conversation-3.svg"}
      headerText={t("in_app.ssn_blocked.title")}
      headerSubtext={t("in_app.ssn_blocked.description")}
      open={ssnBlockedInApp}
      onClose={handleCancel}
    >
      <Button hasSpace onClick={handleCancel} text={t("in_app.ssn_blocked.button")} />
    </InApp>

    <InApp headerText={t("in_app.gender.header")} open={openSelectGender} onClose={handleToggleGenderInApp}>
      <div className={styles.gender_inApp}>
        <div className={styles.gender_inApp_items}>
          {genders.map((gender) =>
            <RadioInput
              id={gender.id}
              name={gender.id}
              key={gender.id}
              value={gender.id}
              label={gender.label}
              checked={gender.id === getValues("gender")}
              onChange={handleOnChangeGender}
            />)}
        </div>
      </div>
    </InApp>

    <InApp
      headerText={t("in_app.birth_day.header")}
      open={openSelectBirthDay}
      contentDrag={true}
      onClose={handleToggleBirthDayInApp}
    >
      <div className={styles.gender_inApp}>
        <div className={styles.gender_inApp_items}>
          <DatePicker onClose={handleToggleBirthDayInApp} setDate={handleSetBirthDay} />
        </div>
      </div>
    </InApp>
  </>
}

export default ManualRegistration;