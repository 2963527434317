import React                    from "react";
import {Nullable}               from "../../model";
import styles                   from "./Tabs.module.scss";
import cn                       from "classnames";
import {formatNumberWithSpaces} from "../../lib";


interface NewTabItem {
  text: string;
  subtext?: string;
  icon?: string;
  rightIcon?: string;
  id: Nullable<number | string>;
  count?: number;
  is_selected?: Nullable<boolean>;
}

interface Props {
  header?: string;
  list: NewTabItem[];
  children?: React.ReactNode;
  currentTab?: Nullable<string | number>;
  type?: "standard" | "additional";
  onSetCurrentTab: (value?: Nullable<string | number>, count?: number) => void;
  isFullPosition?: boolean;
  className?: string;
  disable?: boolean;
}

function Tabs({
  header,
  list,
  children,
  currentTab: propTab,
  onSetCurrentTab,
  type = "standard",
  isFullPosition = false,
  disable = false,
  className,
}: Props) {
  const [currentTab, setCurrentTab] = React.useState<Nullable<string | number>>(null);

  const handleSetCurrentTab = (id: Nullable<number | string>, count?: number) => {
    if (disable) return;
    setCurrentTab(id);
    onSetCurrentTab(id, count);
  }

  React.useEffect(() => {
    if (propTab !== undefined)
      setCurrentTab(propTab);
  }, [propTab]);

  return <div className={cn(styles.wrapper, className)}>
    {header && <span className={styles.header}>{header}</span>}

    <ul className={cn(styles.tabs_holder, {[styles.full_position]: isFullPosition})}>
      {list.map((item) => {
        if (type === "standard") {
          return <li
            key={item.id}
            className={cn(styles.tab, {[styles.active]: currentTab === item.id})}
            onClick={() => handleSetCurrentTab(item.id, item.count)}
          >
            {item.icon && <span className={`${styles.tab_icon} mgr-2`}>{item.icon}</span>}
            <span
              className={styles.tab_text}>{item.text} {typeof item.count === "number" && `(${formatNumberWithSpaces(item.count)})`}</span>
            {item.rightIcon && <span className={`${styles.tab_icon} mgl-2`}>{item.rightIcon}</span>}
          </li>
        }
        return <li
          key={item.id}
          className={cn(styles.additional_item, {[styles.active]: currentTab === item.id})}
          onClick={() => handleSetCurrentTab(item.id)}
        >
          <span className={styles.title}>{item.text}</span>
          <span className={styles.subtext}>{item.subtext}</span>
        </li>
      })}
    </ul>

    {children && <div className="mgt-2">{children}</div>}
  </div>
}

export default React.memo(Tabs);