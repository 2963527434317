export const order = {
  header:                       {
    title:    "Қызметке өтінім",
    subtitle: [
      "1-қадам 3-тен — сипаттама",
      "2-қадам 3-тен — төлем",
      "3-қадам 3-тен — байланыс",
    ],
  },
  labels:                       {
    description:       "Сипаттама",
    date_and_time:     "Қызмет қашан қажет",
    date:              "Күні",
    time:              "Уақыты",
    select_date:       "Күні мен уақытын таңдаңыз",
    media:             "Фото және видео",
    price:             "Сіздің бағаңыз",
    online_payment:    "Онлайн төлем",
    online_pay:        "Онлайн төлеймін",
    to:                "дейін",
    communication_way: "Сізбен қалай байланысуға болады?",
    address:           "Мекенжай",
  },
  placeholders:                 {
    description:    "Не істеу қажет?",
    online_payment: "Қызметтерді төлеудің ең ыңғайлы, қауіпсіз және тиімді тәсілі. Толығырақ",
    address:        "қ. {{city}}, мекенжай көрсетілмеген",
  },
  hints:                        {
    description:       "Сипаттаманы толығырақ көрсетсеңіз, әр маманмен мәліметтерді нақтылаудың қажеті болмайды",
    media:             "Фото немесе видео қосу арқылы мамандарға не істеу керегін көрсете аласыз",
    communication_way: "Байланыс тәсілінен 'Чатта жазу' опциясын таңдауды ұсынамыз, бұл қауіпсіздеу",
    address:           "Мекенжайды көрсеткен жөн, бұл мамандарға жол мен уақытты жоспарлауға көмектеседі",
  },
  date_list:                    {
    fast:        "Шұғыл",
    today:       "Бүгін",
    tomorrow:    "Ертең",
    select_date: "Күні мен уақытын таңдау",
  },
  day_list:                     [
    "жс",
    "дс",
    "сс",
    "ср",
    "бс",
    "жм",
    "сб",
  ],
  payment_info:                 [
    "— Егер жұмыс аяқталған болса, жедел аударымдар жасаңыз",
    "— Алдын ала төлем жасап, оны жұмыс аяқталғаннан кейін растаңыз. Бұл бағаның өсуі мен қызмет сапасының төмендеуін болдырмайды",
    "— Онлайн төлемдерде Freedom Bank кешбэктері мен naimi бонустары жиі қолжетімді",
    "— Несие немесе бөліп төлеу, Kaspi, Freedom SuperApp және басқа да әдістермен төлеуге болады",
  ],
  communication_way:            {
    chat: "Чатта жазыңыз",
    call: "Маған қоңырау шалыңыз",
  },
  add_more_button:              "Тағы қосу",
  select_online_payment_button: "Онлайн төлемді таңдау",
  create_button:                "Өтінім жасау",
  close_order:                  {
    title:              "Нақты шыққыңыз келеді ме?",
    subtext:            "Сіз сенімдісіз бе? Өтінім жасаңыз және ұсыныстарды көріңіз. Бұл тегін — сіз ештеңе жоғалтпайсыз!",
    subtext_with_bonus: {
      first_part:  "Алғашқы өтінімді жасағанда клиенттердің шығып кетуі біз үшін өкінішті, сондықтан сізге",
      second_part: "{{bonus}} бонус",
      third_part:  "онлайн төлем кезінде үнемдеу үшін берілді. Өтінім жасаңыз және ұсыныстарды көріңіз. Бұл тегін — сіз ештеңе жоғалтпайсыз!",
    },
  },
  first_step_description:       {
    update: "Сипаттаманы толықтыру",
    submit: "Сол күйінде қалдыру",
  },
  save_order:                   {
    remind_later:   "Кейін еске салу",
    set_permission: "Хабарламаларға рұқсат беру",
  },
}