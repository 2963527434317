import React                                         from "react";
import {useTranslation}                              from "react-i18next";
import {useNavigate}                                 from "react-router-dom";
import {InApp}                                       from "shared/ui";
import {defineOpenShareLinkType, setActionForNative} from "shared/lib";
import {NativeActions}                               from "shared/model";
import styles                                        from "./ProfileSettings.module.scss";
import {Icon, NewList}                               from "shared/v12ui";


interface Props {
  isOpen: boolean;
  onToggle: () => void;
  onToggleClientWatch: () => void;
  id?: number;
}

export default function ProfileSettings({id, isOpen, onToggle, onToggleClientWatch}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleRemoveProfileClick = () => {
    navigate("/profile/remove");
    setActionForNative(NativeActions.ANALYTICS, {
      event: "specialist my ankety delete open",
    });
  };

  const handleOpenShare = () => {
    setActionForNative
    (NativeActions.OPEN_SHARE,
      {text: t(`page.specialist.profile.more.open_share`, {link: defineOpenShareLinkType(id)})},
    );
  }

  return <InApp open={isOpen} onClose={onToggle}>
    <div className={styles.settings_wrapper}>
      <NewList
        icon="edit_view"
        title={t("page.specialist.profile.more.opt_1")}
        controlIcon={<Icon>keyboard_arrow_right</Icon>}
        onClick={onToggleClientWatch}
      />

      <NewList
        icon="forward"
        title={t("page.specialist.profile.more.opt_2")}
        onClick={handleOpenShare}
        controlIcon={<Icon>keyboard_arrow_right</Icon>}
      />

      <NewList
        icon="delete"
        title={t("page.specialist.profile.more.opt_3")}
        controlIcon={<Icon>keyboard_arrow_right</Icon>}
        onClick={handleRemoveProfileClick}
      />
    </div>
  </InApp>
};