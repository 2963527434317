import React                               from "react";
import {createPortal}                      from "react-dom";
import {
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
}                                          from "react-hook-form";
import {
  YMap,
  YMapDefaultSchemeLayer,
  reactify,
  YMapDefaultFeaturesLayer,
  YMapListener,
  YMapMarker,
  YMapControl,
}                                          from "lib/yamaps";
import {LngLat, YMapLocationRequest}       from "@yandex/ymaps3-types";
import {CitySelect}                        from "features/global/citySelect";
import {Icon}                              from "shared/v12ui";
import {NativeActions}                     from "shared/model";
import {useNativeHandler}                  from "shared/lib";
import {useAppDispatch, useAppSelector}    from "store";
import {selectCurrentCity, setCurrentCity} from "store/features";
import {AddressesItem}                     from "./model/addressItem";
import {yaMapSearch}                       from "./model/yaMapHelpers";
import {customLayer}                       from "./model/yaMapCustomSchemeLayer";
import useStopScrolling                    from "./model/useStopScrolling";
import GeneralMark                         from "./ui/marks/GeneralMark";
import MarkWithNumber                      from "./ui/marks/MarkWithNumber";
import SearchList                          from "./ui/searchList/SearchList";
import {CreateState}                       from "../../pages/order/model/createState";
import styles                              from "./YaMap.module.scss";
import {useLazyFetchCurrentCityQuery}      from "../../pages/order";


interface Props {
  updatedIndex?: number;
  addressName?: string;
  addresses: AddressesItem[];
  onSetAddress: UseFieldArrayAppend<CreateState, "addresses">
  onUpdateAddress: UseFieldArrayUpdate<CreateState, "addresses">;
  onClose: () => void;
}

const LOCATION: YMapLocationRequest = {
  center: [71.427552, 51.128064],
  zoom:   15,
};

function YaMap({
  updatedIndex,
  addressName,
  addresses,
  onSetAddress,
  onClose,
  onUpdateAddress,
}: Props) {
  const dispatch = useAppDispatch();
  const currentCity = useAppSelector(selectCurrentCity);
  const debounceTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const [getCurrentCity] = useLazyFetchCurrentCityQuery();

  const [location, setLocation] = React.useState<YMapLocationRequest>(LOCATION);
  const [suggestValue, setSuggestValue] = React.useState("");
  const [openCitySelect, setOpenCitySelect] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(true);

  const handleToggleCitySelect = () => setOpenCitySelect(prev => !prev);

  const handleCitySelectClick = () => handleToggleCitySelect();

  const handleSetGeolocation = async (data: { longitude: number, latitude: number }) => {
    if ((!data.latitude && !data.latitude) || !data) return;
    const currentGeo = [data.longitude, data.latitude];

    getCurrentCity({longitude: data.longitude, latitude: data.latitude});

    try {
      const [cityData, searchResults] = await Promise.all([
        getCurrentCity({longitude: data.longitude, latitude: data.latitude}),
        yaMapSearch({text: `${currentGeo}`, limit: 1}),
      ]);

      const city = cityData.data?.city;

      const coordinates = searchResults[0]?.geometry?.coordinates as LngLat;
      if (coordinates) {
        setLocation({center: coordinates, zoom: 17});
      }

      if (city) {
        dispatch(setCurrentCity({id: city.id, name: city.name}));
      }
    } catch (error) {
      console.warn("ошибка search", error)
    }
  }

  React.useEffect(() => {
    if (currentCity) {
      yaMapSearch({text: `Казахстан, ${currentCity.name}`, limit: 1}).then((res) => {
        setLocation({center: res[0].geometry?.coordinates as LngLat, zoom: 15});
      }).catch((error) => console.warn("ошибка search", error))
    }
  }, [currentCity]);

  React.useEffect(() => {
    if (updatedIndex === undefined) return;
    const currentIndex = addresses.findIndex((_, fieldIndex) => fieldIndex === updatedIndex);
    if (currentIndex !== -1) {
      const currentAddress = addresses[currentIndex];

      yaMapSearch({text: `${currentAddress.coord}`, limit: 1}).then((res) => {
        setLocation({center: res[0].geometry?.coordinates as LngLat, zoom: 15});
      }).catch((error) => console.warn("ошибка search", error));
    }
  }, [updatedIndex, addresses]);

  useNativeHandler<{ longitude: number, latitude: number }>(null, NativeActions.GET_GEO_COORDS, (data) => {
    return handleSetGeolocation(data);
  });

  useStopScrolling();

  return <>
    {createPortal(<div className={styles.wrapper}>
      <div className={styles.shadow_holder}>
        <div className={styles.header_content_holder}>
          <button className={styles.close_button} type="button" onClick={onClose}>close</button>
          <div className={styles.city_select} onClick={handleCitySelectClick}>
            <span className={styles.city_select_text}>{`г. ${currentCity?.name}`}</span>
            <Icon size={16} iconColor="#828291">keyboard_arrow_down</Icon>
          </div>
        </div>
      </div>

      {reactify && <YMap
        mode="vector"
        location={reactify.useDefault(location, [location])}
        copyrights={false}
      >
        <YMapDefaultFeaturesLayer />
        <YMapDefaultSchemeLayer customization={customLayer} />
        <YMapControl>
          <YMapListener
            onUpdate={(event) => {
              if (openSearch) return;
              if (!event.mapInAction) {
                if (debounceTimeout.current) {
                  clearTimeout(debounceTimeout.current);
                }
                debounceTimeout.current = setTimeout(() => {
                  yaMapSearch({text: `${event.location.center}`, limit: 1})
                    .then((res) => {
                      setSuggestValue(res[0]?.properties?.name ?? "Астана")
                    }).catch((error) => console.warn("ошибка search", error))
                }, 700);
              }
            }}
          />
        </YMapControl>

        {addresses.map((address, index) => {
          return <YMapMarker
            key={index}
            coordinates={reactify.useDefault(address.coord as LngLat, [addresses])} draggable={false}>
            <section className={styles.marker_holder} style={{opacity: index === updatedIndex ? 0 : 1}}>
              <MarkWithNumber count={index + 1} />
            </section>
          </YMapMarker>
        })}

        <div className={styles.centered_mark}>{!addresses.length
          ? <GeneralMark />
          : <MarkWithNumber count={typeof updatedIndex === "number" ? updatedIndex + 1 : addresses.length + 1} />}
        </div>

        <SearchList
          openSearch={openSearch}
          suggestValue={suggestValue}
          addresses={addresses}
          addressName={addressName}
          updatedIndex={updatedIndex}
          onSetAddress={onSetAddress}
          onUpdateAddress={onUpdateAddress}
          onMapClose={onClose}
          onSetOpenSearch={setOpenSearch}
          onSetSuggestValue={setSuggestValue}
          onSetLocation={setLocation}
        />
      </YMap>}

      <CitySelect open={openCitySelect} onClose={handleToggleCitySelect} />
    </div>, document.body)}
  </>
}

export default YaMap;