import React                                                                        from "react";
import {useTranslation}                                                             from "react-i18next";
import {useNavigate}                                                                from "react-router-dom";
import {SkeletonPrices}                                                             from "entities/skeleton";
import {NewPageLayout, SystemResponse}                                              from "entities/general";
import {useFetchPricesServicesListQuery}                                            from "shared/api";
import {Button, Icon, NewList}                                                      from "shared/v12ui";
import {setActionForNative, useNativeHandler, useSetHeaders, useSkeletonThrottling} from "shared/lib";
import {NativeActions}                                                              from "shared/model";
import styles                                                                       from "./ProfilePrices.module.scss";


const i18n_prefix = "page.specialist.profile.prices.update."
export default function ProfilePrices() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {isSuccess} = useSetHeaders();

  const {data, isLoading, refetch} = useFetchPricesServicesListQuery(null, {
    skip:                      !isSuccess,
    refetchOnMountOrArgChange: true,
  });

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  const handleSelectService = (id: number) => {
    navigate(`/profile/prices-service/${id}`);
  }

  const handleBackClick = () => navigate("/profile");

  const handleEditNewsLineClick = () => {
    setActionForNative(NativeActions.OPEN_NEWSLINE_FILTERS);
  }

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return handleBackClick();
  });

  useNativeHandler<{ is_changed: boolean }>("profile", NativeActions.OPEN_NEWSLINE_FILTERS, data => {
    if (data.is_changed) {
      refetch();
    }
  });

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    headerSubtitle={t(`${i18n_prefix}subtitle`)}
    headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    headerRightIcon={<Icon className="text black" onClick={handleEditNewsLineClick}>edit</Icon>}
    isLoading={extendedLoading}
  >
    {extendedLoading
      ? <SkeletonPrices count={6} hasDark />
      : data?.services.map((service) => {
        return <NewList
          key={service.id}
          title={service.name}
          subtitle={t(`${i18n_prefix}service_subtitle`, {count_from: service.nmb_active, count_to: service.total})}
          controlIcon={<Icon>keyboard_arrow_right</Icon>}
          onClick={() => handleSelectService(service.id)}
        />
      })}

    {!extendedLoading && !data?.services.length && <div className={styles.empty_services_wrapper}>
      <SystemResponse
        icon="info"
        title={t(`${i18n_prefix}empty_prices.title`)}
        description={t(`${i18n_prefix}empty_prices.subtitle`)}
      >
        <Button
          isRounded
          text={t(`${i18n_prefix}empty_prices.button`)}
          onClick={handleEditNewsLineClick}
        />
      </SystemResponse>
    </div>}
  </NewPageLayout>
};
