import React                                  from "react";
import {useTranslation}                       from "react-i18next";
import {NewPageLayout}                        from "entities/general/newPageLayout";
import {SubmitHandler, useForm}               from "react-hook-form";
import {Button, NewButtonColor}               from "shared/v12ui";
import {setActionForNative, useNativeHandler} from "shared/lib";
import {NativeActions, Nullable}              from "shared/model";
import {formatAddressName, yaMapSearch}       from "widgets/yaMap/model/yaMapHelpers";
import {useAppDispatch, useAppSelector}       from "store";
import {setApiSession, setCurrentCity}        from "store/features";
import {
  selectGeolocationPermission,
  selectPushPermission,
  setPushPermission,
}                                             from "features/permissions";
import {useCheckGeoPermission}                from "features/permissions/geolocation";
import {CreateState}                          from "./model/createState";
import {useFetchOrderDetails}                 from "./model/fetchOrderDetails";
import {DateListType}                         from "./model/dateListType";
import {getCurrentDate}                       from "./model/formatDates";
import FirstStepOrder                         from "./ui/firstStep/FirstStepOrder";
import SecondStepOrder                        from "./ui/secondStep/SecondStepOrder";
import ThirdStepOrder                         from "./ui/thirdStep/ThirdStepOrder";
import {
  useFetchOrderRecommendationMutation,
  useLazyFetchCurrentCityQuery,
  useSaveOrderMutation,
}                                             from "./api";
import OrderSaveInApp                         from "./ui/orderSaveInApp/OrderSaveInApp";
import DescriptionInApp                       from "./ui/descriptionInApp/DescriptionInApp";
import CloseOrderForm                         from "./ui/closeOderForm/CloseOrderForm";
import {getDateList}                          from "./model/dateList";
import {selectTicketIsDuplicated}             from "./model/orderSlice";


export default function Order() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const geolocationPermission = useAppSelector(selectGeolocationPermission);
  const pushPermission = useAppSelector(selectPushPermission);
  const isDuplicatedTicket = useAppSelector(selectTicketIsDuplicated);

  const [getCurrentCity] = useLazyFetchCurrentCityQuery();
  const {orderDetails, ticketId, workId, isDemo} = useFetchOrderDetails();
  const [createOrder, {data: createOrderResponse, isLoading: createOrderIsLoading}] = useSaveOrderMutation();
  const [getRecommendation, {
    data:      recommendations,
    isLoading: recommendationsIsLoading,
    reset:     resetRecommendations,
  }] = useFetchOrderRecommendationMutation();

  const [step, setStep] = React.useState(1);
  const [hasSuccessResponse, setHasSuccessResponse] = React.useState(false);
  const [gettingGeolocation, setGettingGeolocation] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [openMap, setOpenMap] = React.useState(false);
  const [openRecommendation, setOpenRecommendation] = React.useState(false);
  const [dateList, setDateList] = React.useState<DateListType[]>(getDateList(t));

  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    watch,
    formState: {errors},
    setError,
    clearErrors,
  } = useForm<CreateState>({
    defaultValues: {
      description:     undefined,
      price:           undefined,
      isAppPaymentWay: false,
      addresses:       [],
      priceUnit:       undefined,
      phone:           undefined,
      isAllowed:       false,
      isFast:          false,
      media:           [],
      uploadedMedia:   [],
      dateTab:         undefined,
    },
  });

  const handleIncreaseStep = () => {
    if (step === 3) return;
    setStep(prev => prev + 1);
  };

  const handleButtonClick = () => {
    if (recommendationsIsLoading) return;
    if (openRecommendation) return;

    if (step === 3) {
      if (isDemo && watch("isAllowed") === true && !isLoggedIn) {
        if (!watch("phone")) {
          //TODO добавить перевод
          setError("phone", {message: "Укажите номер телефона"});
          return;
        }
        const phone = watch("phone")?.replace(/\D/g, "");
        return setActionForNative(NativeActions.LOGIN, {phone});
      }
      return handleSubmit(onSubmit)();
    }
    if (step === 2) {
      if (watch("price")) {
        if (+(watch("price") as number) < watch("price_limit.min")) {
          //TODO добавить перевод
          setError("price", {message: "Цена не может быть ниже 100 ₸"});
          return;
        }
        if (+(watch("price") as number) > watch("price_limit.max")) {
          //TODO добавить перевод
          setError("price", {message: `Цена не может быть выше ${watch("price_limit.max")} ₸`});
          return;
        }
      }
      if (geolocationPermission === true) {
        if (!watch("addresses").length) {
          setActionForNative(NativeActions.GET_GEO_COORDS);
          setGettingGeolocation(true);
        } else {
          handleIncreaseStep();
        }
      } else {
        return handleIncreaseStep();
      }
    }
    if (step === 1) {
      if (watch("description").trim() === "") {
        //TODO перевод
        setError("description", {message: "Поле обязательно для заполнения"});
        return;
      }
      if (isDuplicatedTicket) {
        return getRecommendation({description: watch("description")});
      } else {
        return getRecommendation({description: watch("description"), ticket_id: ticketId ?? undefined});
      }
    }
  }

  const handleSetGeolocation = async (data: { longitude: number, latitude: number }) => {
    if ((!data.latitude && !data.latitude) || !data) {
      setGettingGeolocation(false);
      handleIncreaseStep();
      return;
    }
    const currentGeo = [data.longitude, data.latitude];
    try {
      const [cityData, searchResults] = await Promise.all([
        getCurrentCity({longitude: data.longitude, latitude: data.latitude}),
        yaMapSearch({text: `${currentGeo}`, limit: 1}),
      ]);

      const city = cityData.data?.city;

      const addresses = searchResults.map((res: any) => ({
        coord:     res.geometry?.coordinates as number[],
        name:      formatAddressName(res.properties.name) as string,
        city_id:   city?.id,
        city_name: city?.name,
      }));

      if (city) {
        dispatch(setCurrentCity({id: city?.id, name: city?.name}))
      }

      setValue("addresses", addresses);
    } catch (error) {
      console.warn("ошибка search", error)
    } finally {
      setGettingGeolocation(false);
      handleIncreaseStep();
    }
  }

  const handleLogin = (data: { session: Nullable<string>, is_logged_in: boolean }) => {
    setIsLoggedIn(data.is_logged_in);
    if (data.is_logged_in) {
      if (data.session) {
        dispatch(setApiSession(data.session));

        const addresses = watch("addresses").map((address) => ({
          address:   address.name,
          longitude: address.coord[0],
          latitude:  address.coord[1],
          city_id:   address.city_id,
        }));

        const getTicketDate = () => {
          if (watch("dateTab") !== "fast" && watch("dateTab") !== "selectDate") {
            return watch("date");
          }
          if (watch("dateTab") === "selectDate") {
            return watch("dateAndTime.date");
          }
          return undefined;
        }

        const time = watch("dateAndTime.time") ? watch("dateAndTime.time")?.replace(/\s/g, "") : undefined;

        const media = watch("media")?.filter((item) => item.digest !== undefined)
                                    .map((item) => ({type: item.type, digest: item.digest as string}));

        const currentTicketId = isDuplicatedTicket || !ticketId ? undefined : ticketId;

        createOrder({
          ticket_id:          currentTicketId,
          work_id:            workId ? workId : undefined,
          is_allowed:         watch("isAllowed"),
          description:        watch("description"),
          addresses:          addresses ?? [],
          work_unit_id:       null,
          price_to:           watch("price"),
          is_fast:            watch("isFast"),
          is_app_payment_way: watch("isAppPaymentWay"),
          date:               getTicketDate(),
          media,
          time,
        });
      }
    }
  }

  const handleBackStepClick = () => setStep(prev => prev - 1);

  const onSubmit: SubmitHandler<CreateState> = (data) => {
    const addresses = data.addresses.map((address) => ({
      address:   address.name,
      longitude: address.coord[0],
      latitude:  address.coord[1],
      city_id:   address.city_id,
    }));

    const getTicketDate = () => {
      if (data.dateTab !== "fast" && data.dateTab !== "selectDate") {
        return data.date;
      }
      if (data.dateTab === "selectDate") {
        return data.dateAndTime?.date;
      }
      return undefined;
    }

    const time = data.dateAndTime?.time ? data.dateAndTime.time.replace(/\s/g, "") : undefined;

    const media = data.media?.filter((item) => item.digest !== undefined)
                      .map((item) => ({type: item.type, digest: item.digest as string}));
    createOrder({
      ticket_id:          ticketId ? ticketId : undefined,
      work_id:            workId ? workId : undefined,
      is_allowed:         data.isAllowed,
      description:        data.description,
      addresses:          addresses ?? [],
      work_unit_id:       null,
      price_to:           data.price,
      is_fast:            data.isFast,
      is_app_payment_way: data.isAppPaymentWay,
      date:               getTicketDate(),
      media,
      time,
    });
  }

  React.useEffect(() => {
    if (orderDetails) {
      const {ticket} = orderDetails;

      const getDateAndTime = () => {
        if (ticket.date && ticket.time) {
          const time = ticket.time.replace("-", " - ");
          return {date: ticket.date, time};
        }
        return undefined;
      }

      const setDateTab = () => {
        if (ticket.is_fast) {
          return "fast";
        }
        if (ticket.date && ticket.time) {
          if (new Date(ticket.date) >= new Date(getCurrentDate())) {
            return "selectDate"
          }
        }
        if (ticket.date) {
          return ticket.date;
        }
        return undefined;
      }

      const media = ticket.media?.map((item) => ({
        uuid:     undefined,
        type:     item.type === "photo" ? "img" : "video",
        base64:   undefined,
        percent:  100,
        isFailed: false,
        digest:   item.digest,
      }));

      const price = typeof ticket.price_to === "number" ? ticket.price_to : undefined;

      const data = {
        step:             1,
        description:      ticket.description,
        price,
        isAppPaymentWay:  orderDetails.ticket.is_app_payment_way,
        isAllowed:        ticket.is_allowed,
        addresses:        ticket.addresses?.map((address) => ({
          coord:     [+address.longitude, +address.latitude],
          name:      address.address,
          city_id:   address.city.id,
          city_name: address.city.name,
        })) ?? [],
        priceUnit:        ticket.work_units?.find((item) => item.is_selected)?.name,
        workUnits:        ticket.work_units ?? [],
        phone:            isDemo ? undefined : ticket.phone.slice(2),
        recommendedPrice: ticket.recommended_price,
        media,
        uploadedMedia:    [],
        isFast:           ticket.is_fast,
        dateTab:          setDateTab(),
        dateAndTime:      getDateAndTime(),
        date:             !ticket.time && ticket.date ? ticket.date : undefined,
        price_limit:      {
          min: orderDetails.price_limit?.min,
          max: orderDetails.price_limit?.max,
        },
      }

      reset(data);

      if (ticket.date) {
        if (new Date(ticket.date) < new Date(getCurrentDate())) {
          const currentDate = new Date(ticket.date);
          const month = currentDate.getMonth() + 1;
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();

          const dateString = `${day}.${month}.${year}`;
          setDateList(prev => ([{id: ticket.date as string, text: dateString}, ...prev]));
        } else {
          if (ticket.time) {
            const date = getDateAndTime()?.date;
            const currentDate = new Date(date as string);
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            const dateString = `${day}.${month}`;

            setDateList(prev => prev.map((item) => item.id === "selectDate" ? ({
              ...item,
              text: `${dateString} | ${getDateAndTime()?.time}`,
            }) : item));
          }
        }
      }
    }
  }, [orderDetails, isDemo]);

  React.useEffect(() => {
    if (pushPermission === null) {
      return setActionForNative(NativeActions.HAS_PERMISSIONS_PUSH);
    }
  }, [pushPermission]);

  useNativeHandler<{ longitude: number, latitude: number }>(null, NativeActions.GET_GEO_COORDS, (data) => {
    if (openMap) return;
    if (!!watch("addresses").length) return;
    return handleSetGeolocation(data);
  });

  useNativeHandler<{ session: Nullable<string>, is_logged_in: boolean }>(null, NativeActions.LOGIN, (data) => {
    return handleLogin(data);
  });

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_PUSH, (data) => {
    return dispatch(setPushPermission(data.has_permissions));
  });

  useCheckGeoPermission({hasGeoPermission: geolocationPermission});

  return <>
    <form onSubmit={handleSubmit(onSubmit)} style={{height: "100%"}}>
      <NewPageLayout
        hasToast={false}
        headerRightIcon={<CloseOrderForm description={watch("description")} />}
        headerTitle={t("page.order.header.title")}
        headerSubtitle={t(`page.order.header.subtitle.${step - 1}`)}
        step={step}
        footer={<Button
          type="button"
          additionalIcon="keyboard_arrow_left"
          onClick={handleButtonClick}
          onAdditionalButtonClick={handleBackStepClick}
          buttonColor={step !== 3 ? NewButtonColor.BLACK : NewButtonColor.SUCCESS}
          text={step !== 3 ? t("common.next") : t("page.order.create_button")}
          loading={recommendationsIsLoading || gettingGeolocation || createOrderIsLoading}
          disabled={!!errors.description}
        >
          {step !== 1 && <Button.AdditionalButton />}
        </Button>}
      >
        {step === 1 && <FirstStepOrder
          watch={watch}
          errors={errors}
          control={control}
          register={register}
          labels={orderDetails?.labels?.media}
          setValue={setValue}
          dateList={dateList}
          clearErrors={clearErrors}
          onSetDateList={setDateList}
        />}

        {step === 2 && <SecondStepOrder
          watch={watch}
          control={control}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          labels={orderDetails?.labels?.app_payment}
          workUnits={watch("workUnits")}
          setValue={setValue}
        />}

        {step === 3 &&
          <ThirdStepOrder
            register={register}
            watch={watch}
            control={control}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            labels={orderDetails?.labels?.addresses}
            openMap={openMap}
            setOpenMap={setOpenMap}
          />}
      </NewPageLayout>
    </form>

    {recommendations && <DescriptionInApp
      isEditOrder={!!ticketId}
      open={openRecommendation}
      recommendations={recommendations}
      setValue={setValue}
      setError={setError}
      setOpen={setOpenRecommendation}
      onIncreaseStep={handleIncreaseStep}
      successResponse={hasSuccessResponse}
      onSetSuccessResponse={setHasSuccessResponse}
      resetRecommendation={resetRecommendations}
    />}

    {createOrderResponse?.alert &&
      <OrderSaveInApp hasPushPermission={pushPermission} alert={createOrderResponse?.alert} />}
  </>
};