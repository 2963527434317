import React                         from "react";
import {IMaskMixin, IMaskMixinProps} from "react-imask";
import {UnmaskValue}                 from "react-imask/mixin";
import {FactoryArg}                  from "imask/masked/factory";
import {InputMask}                   from "imask";
import BaseInputProps                from "../BaseInputProps";
import {Input}                       from "../../../v12ui";

type Opts = {
  unmask?: "typed" | boolean;
}

interface Props extends Omit<BaseInputProps, "onChange" | "value"> {
  mask: any;
  value?: string;
  onAccept?: (value: UnmaskValue<Opts>, mask?: InputMask<FactoryArg>) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  inputRef?: React.Ref<HTMLInputElement>;
  errorMessage?: string;
}

const MaskedStyledInput = IMaskMixin(({inputRef, ...props}: IMaskMixinProps<any>) => (
  //@ts-ignore
  <Input
    {...props}
    ref={inputRef}
  />
));

const InputPhone = React.forwardRef(({
  mask,
  value,
  onChange,
  name,
  onAccept,
  inputRef,
  errorMessage,
  // isphonetype,
  ...restProps
}: Props, ref) => {
  const handleAccept = (maskedValue: UnmaskValue<Opts>, mask?: InputMask<FactoryArg>) => {
    onChange({target: {name, value: maskedValue.toString()}});

    if (onAccept) {
      onAccept(maskedValue, mask);
    }
  };

  return (
    <MaskedStyledInput
      //@ts-ignore
      mask={mask}
      errorMessage={errorMessage}
      value={value}
      onAccept={handleAccept}
      inputRef={ref}
      {...restProps}
    />
  );
});

export default InputPhone;