import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {InApp}                                 from "shared/ui";
import {setActionForNative, useNativeHandler}  from "shared/lib";
import {NativeActions}                         from "shared/model";
import {useAppDispatch, useAppSelector}        from "store";
import {
  selectGalleryInAppIsOpen,
  selectGalleryPermission,
  setGalleryPermission,
  toggleGalleryInApp,
}                                              from "../model/permissionsSlice";


interface Props {
  limit: number;
  hasVideo: boolean;
}
const MEDIA_LIMIT = 5;
const i18nPrefix = "features.permissions.gallery."
export default function GalleryRequest({limit, hasVideo}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const galleryPermission = useAppSelector(selectGalleryPermission);
  const galleryInAppIsOpen = useAppSelector(selectGalleryInAppIsOpen);

  const handleCloseInAppClick = () => {
    dispatch(toggleGalleryInApp(false));
  }

  const handleRequestGalleryPermission = () => {
    setActionForNative(NativeActions.GIVE_GALLERY_PERMISSION);
  }

  const handleOpenGallery = () => {
    dispatch(setGalleryPermission(true));
    setActionForNative(NativeActions.OPEN_GALLERY, {limit: MEDIA_LIMIT - limit, has_video: hasVideo});
    dispatch(toggleGalleryInApp(false));
  }

  React.useEffect(() => {
    if (galleryPermission === null) {
      return setActionForNative(NativeActions.HAS_PERMISSIONS_GALLERY);
    }
  }, [galleryPermission]);

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_GALLERY, data => {
    if (data) {
      return dispatch(setGalleryPermission(data.has_permissions));
    }
  });

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.GIVE_GALLERY_PERMISSION, data => {
    if (data.has_permissions) {
      return handleOpenGallery();
    } else {
      return dispatch(toggleGalleryInApp(false));
    }
  });

  return <>
    {galleryInAppIsOpen && <InApp
      image="/illustrations/content-media.svg"
      headerText={t(`${i18nPrefix}text`)}
      headerSubtext={t(`${i18nPrefix}subtext`)}
      open={galleryInAppIsOpen}
      onClose={handleCloseInAppClick}
    >
      <div className="inApp_buttons">
        <Button
          type="button"
          text={t("common.cancel")}
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={handleCloseInAppClick}
        />

        <Button
          type="button"
          text={t("common.allow")}
          hasSpace
          onClick={handleRequestGalleryPermission}
        />
      </div>
    </InApp>}
  </>
};