import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {useNavigate}                           from "react-router-dom";
import {NewPageLayout}                         from "entities/general";
import {Button, Icon, NewList}                 from "shared/v12ui";
import {NativeActions, setAvatarIsStartUpload} from "shared/model";
import {useNativeHandler, setActionForNative}  from "shared/lib";
import {useAppDispatch}                        from "store";
import styles                                  from "./ProfileAvatarChange.module.scss";


const i18n_prefix = "page.specialist.profile.change_avatar."
export default function ProfileAvatarChange() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleBackClick = () => navigate("/profile");

  const onChangeAvatarClick = () => {
    return setActionForNative(NativeActions.OPEN_AVATAR_UPLOAD);
  }

  const uploadStart = () => {
    dispatch(setAvatarIsStartUpload(true));
    handleBackClick();
  }

  useNativeHandler<{ base64: string }>("profile", NativeActions.AVATAR_UPLOAD_STARTED, () => {
    return uploadStart();
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    return navigate("/profile");
  });

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    headerSubtitle={t(`${i18n_prefix}subtitle`)}
    headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    footer={<Button text={t(`${i18n_prefix}button`)} onClick={onChangeAvatarClick} />}
  >
    <div className={styles.photos_wrapper}>
      <div className={styles.odd_photos}>
        <img
          src="/images/avatar_change_left.png"
          width={96}
          height={96}
          alt=""
        />
        <Icon className={styles.element}>close</Icon>
      </div>

      <div className={styles.middle_photo}>
        <img
          src="/images/avatar_change_middle.png"
          width={120}
          height={120}
          alt=""
        />
        <Icon className={styles.element}>check</Icon>
      </div>

      <div className={styles.odd_photos}>
        <img
          src="/images/avatar_change_right.png"
          width={96}
          height={96}
          alt=""
        />
        <Icon className={styles.element}>close</Icon>
      </div>
    </div>

    {Array.from({length: 4}, (_, index) => {
      return <NewList
        key={index}
        icon="validation"
        title={t(`${i18n_prefix}helper_texts.${index}`)}
      />
    })}
  </NewPageLayout>
};