import React            from "react";
import {useTranslation} from "react-i18next";
import {Button}         from "shared/v12ui";
import styles           from "./styles.module.scss";


interface Props {
  onClick: () => void;
}

export default function ImageLoadError({onClick}: Props) {
  const {t} = useTranslation();

  return <div className={styles.wrapper}>
    <span className={styles.icon_holder}>cancel</span>
    <div className={styles.title}>{t("elements.image_load_error.title")}</div>
    <div className={styles.description}>{t("elements.image_load_error.description")}</div>
    <div className={styles.button}>
      <Button
        isRounded
        text={t("elements.image_load_error.button")}
        onClick={onClick}
      />
    </div>
  </div>
}