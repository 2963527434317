import React                                                from "react";
import {useTranslation}                                     from "react-i18next";
import {Control, Controller, UseFormSetValue, UseFormWatch} from "react-hook-form";
import {InApp}                                              from "shared/ui";
import {Button, Tabs}                                       from "shared/v12ui";
import {DateListType}                                       from "pages/order/model/dateListType";
import {CreateState}                                        from "pages/order/model/createState";
import {filterTimeSlots, formatWeekList}                    from "./model/formatList";
import styles                                               from "./PickOrderDate.module.scss";
import {Nullable}                                           from "shared/model";


interface Props {
  watch: UseFormWatch<CreateState>;
  control: Control<CreateState>;
  open: boolean;
  onClose: () => void;
  onUpdateDateList: React.Dispatch<React.SetStateAction<DateListType[]>>;
  setValue: UseFormSetValue<CreateState>;
}

function PickOrderDate({watch, control, open, onClose, onUpdateDateList, setValue}: Props) {
  const {t} = useTranslation();

  const handleSelectTimeClick = () => {
    const date = watch("dateAndTime.date");
    const currentDate = new Date(date as string);
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    const dateString = `${day}.${month}`;

    onUpdateDateList(prev => prev.map((item) => item.id === "selectDate" ? ({
      ...item,
      text: `${dateString} | ${watch("dateAndTime.time")}`,
    }) : item));

    onClose();
  }

  const handleCloseSelectDateClick = () => {
    const date = watch("dateAndTime.date");
    const currentDate = new Date(date as string);
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    const dateString = `${day}.${month}`;

    onClose();
    setValue("dateTab", undefined);
    setValue("date", undefined);

    onUpdateDateList(prev => prev.map((item) => item.id === "selectDate" ? ({
      ...item,
      text: `${dateString} | ${watch("dateAndTime.time")}`,
    }) : item));
  }

  const handleSelectDateClick = (date?: Nullable<string | number>) => {
    const currentDate = date as string;
    if (!currentDate) return;
    setValue("dateAndTime.date", date as string);
    setValue("dateAndTime.time", filterTimeSlots(currentDate).find((_, index) => index === 0)?.id);
  }

  React.useEffect(() => {
    const dateAndTime = watch("dateAndTime");
    if (!!dateAndTime) {
      const date = watch("dateAndTime.date");
      const currentDate = new Date(date as string);
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();

      const dateString = `${day}.${month}`;

      onUpdateDateList(prev => prev.map((item) => item.id === "selectDate" ? ({
        ...item,
        text: `${dateString} | ${watch("dateAndTime.time")}`,
      }) : item));
    }
  }, [watch("dateAndTime")]);

  return <>
    {open && <InApp onClose={handleCloseSelectDateClick} open={open} headerText={t("page.order.labels.select_date")}>
      <div className={styles.wrapper}>

        <Controller
          control={control}
          name="dateAndTime.date"
          defaultValue={formatWeekList(t).find((_, index) => index === 0)?.id}
          render={({field}) => {
            return <Tabs
              type="additional"
              header={t("page.order.labels.date")}
              currentTab={field.value}
              list={formatWeekList(t)}
              onSetCurrentTab={handleSelectDateClick}
            />
          }}
        />

        <Controller
          control={control}
          name="dateAndTime.time"
          defaultValue={filterTimeSlots().find((_, index) => index === 0)?.id}
          render={({field}) => {
            return <Tabs
              header={t("page.order.labels.time")}
              list={filterTimeSlots(watch("dateAndTime.date"))}
              currentTab={field.value}
              onSetCurrentTab={(time) => {
                setValue("dateAndTime.time", time as string);
              }}
            />
          }}
        />

        <Button text={t("common.select")} hasSpace onClick={handleSelectTimeClick} />
      </div>
    </InApp>}
  </>
}

export default PickOrderDate;