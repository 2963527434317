export const order = {
  header:                       {
    title:    "Заявка на услугу",
    subtitle: [
      "Шаг 1 из 3 — описание",
      "Шаг 2 из 3 — оплата",
      "Шаг 3 из 3 — контакты",
    ],
  },
  labels:                       {
    description:       "Описание",
    date_and_time:     "Когда нужна услуга",
    date:              "Дата",
    time:              "Время",
    select_date:       "Выберите дату и время",
    media:             "Фото и видео",
    price:             "Ваша цена",
    online_payment:    "Онлайн оплата",
    online_pay:        "Оплачу онлайн",
    to:                "до",
    communication_way: "Как с вами связаться?",
    address:           "Адрес",
  },
  placeholders:                 {
    description:    "Что нужно сделать?",
    online_payment: "Самый удобный, безопасный и выгодный способ для оплаты услуг. Подробнее",
    address:        `г. {{city}}, адрес не указан`,
  },
  hints:                        {
    description:       "Указав описание подробнее, вам не потребуется уточнять информацию каждому специалисту",
    media:             "Добавив фото или видео, вы можете показать и рассказать специалистам, что именно нужно сделать",
    communication_way: "Рекомендуем указать выбрать способ связи “Написать в чате”, это безопаснее",
    address:           "Рекомендуем указать адрес, чтобы специалисты могли понять маршрут и время на дорогу",
  },
  date_list:                    {
    fast:        "Срочно",
    today:       "Сегодня",
    tomorrow:    "Завтра",
    select_date: "Выбрать дату и время",
  },
  day_list:                     [
    "вс",
    "пн",
    "вт",
    "ср",
    "чт",
    "пт",
    "сб",
  ],
  payment_info:                 [
    "— Проводите мгновенные переводы, если работа уже выполнена",
    "— Вносите оплату заранее, а подтверждайте после выполнения работ, исключая завышение цен и низкое качество услуг",
    "— При онлайн оплатах часто доступны кешбэки от Freedom Bank и бонусы от naimi",
    "— Можно оплатить в кредит или рассрочки, банковской картой, Kaspi, Freedom SuperApp и др. способы",
  ],
  communication_way:            {
    chat: "Напишите в чат",
    call: "Позвоните мне",
  },
  add_more_button:              "Добавить еще",
  select_online_payment_button: "Выбрать онлайн оплату",
  create_button:                "Создать заявку",
  close_order:                  {
    title:              "Точно хотите выйти?",
    subtext:            "Вы уверены? Создайте заявку и посмотрите предложения. Это бесплатно — вы ничего не теряете!",
    subtext_with_bonus: {
      first_part:  "Мы грустим, когда клиенты хотят выйти при создании своей первой заявки, поэтому начислили вам ",
      second_part: "{{bonus}} бонусов, ",
      third_part:  "чтобы вы могли немного сэкономить при онлайн оплате. Создайте заявку и посмотрите предложения. Это бесплатно — вы ничего не теряете!",
    },
  },
  first_step_description:       {
    update: "Дополнить описание",
    submit: "Оставить как есть",
  },
  save_order:                   {
    remind_later:   "Напомнить позже",
    set_permission: "Разрешить уведомления",
  },
}