export const upload_media = {
  title:    "Фото и видео",
  transfer: {
    title: "Добавить файл",
    count: "{{currentCount}} из {{maxCount}}",
    list:  {
      make_photo:   "Сделать фото",
      record_video: "Записать видео",
      open_gallery: "Выбрать из галереи",
    },
  },
}