import React            from "react";
import {useTranslation} from "react-i18next";
import {SkeletonLayout} from "entities/skeleton";
import styles           from "./BonusesInfoList.module.scss";


interface Props {
  loading: boolean;
  cardsList?: {
    title: string;
    description: string;
  }[];
}

function BonusesInfoList({loading, cardsList}: Props) {
  const {t} = useTranslation();

  return <>
    {loading
      ? <div className={`${styles.info_list_wrapper} mgt-3`}>
        <SkeletonLayout height={22} borderRadius={6} />

        <div className={`${styles.bonuses_info_list} mgt-3`}>
          <SkeletonLayout width={250} height={90} />
          <SkeletonLayout width={250} height={90} />
        </div>
      </div>
      : <>
        {!cardsList?.length
          ? null
          : <div className={styles.info_list_wrapper}>
            <span className={styles.content_header}>{t(`page.customers.invitation.bonuses`)}</span>

            <ul className={styles.bonuses_info_list}>
              {cardsList.map((card, index) => (
                <li key={index} className={styles.info_card_wrapper}>
                  <span className={styles.info_card_title}>{card.title}</span>
                  <span className={styles.info_card_description}>{card.description}</span>
                </li>
              ))}
            </ul>
          </div>}
      </>}
  </>
}

export default React.memo(BonusesInfoList);