import React                               from "react";
import {Outlet, Route, Routes}             from "react-router-dom";
import {useTranslation}                    from "react-i18next";
import {
  Registration,
  FF,
  Egov,
  TransferProfile,
  NotCitizen,
  WithDocument,
  ManualRegistration,
  TransferComplete,
}                                          from "pages/Registration";
import {SpecialistReviews}                 from "pages/Reviews/Specialist";
import {ClientReviews}                     from "pages/Reviews/Client";
import {SelfReviews}                       from "pages/Reviews/Self";
import {CashLoans}                         from "pages/Specialist/loans";
import {Referral}                          from "pages/customers/referral";
import {ClientProfile}                     from "pages/client/profile";
import {SpecialistAppPrices}               from "pages/client/specialist-prices";
import {Order}                             from "pages/order";
import {AutoRenewalCancel}                 from "pages/state";
import {Credits}                           from "pages/Specialist/Credits";
import {CreditsSchedule}                   from "pages/Specialist/Credits/CreditsSchedule";
import {PurchaseListPage}                  from "pages/Specialist/Tariffs/PurchaseListPage";
import {PeriodsListPage}                   from "pages/Specialist/Tariffs/PeriodsListPage";
import {TariffOptionsPage}                 from "pages/Specialist/Tariffs/TariffOptionsPage";
import {PurchaseCatalogueListPage}         from "pages/Specialist/PurchaseCatalogue/PurchaseCatalogueListPage";
import {PurchaseCatalogueServicesListPage} from "pages/Specialist/PurchaseCatalogue/PurchaseCatalogueServicesListPage";
import {SpecialistProfile}                 from "pages/Specialist/profile";
import {ProfileAbout}                      from "pages/Specialist/profile-about";
import {ProfilePrices}                     from "pages/Specialist/profile-prices";
import {PricesService}                     from "pages/Specialist/profile-prices-service";
import {PricesWork}                        from "pages/Specialist/profile-prices-work";
import {RemoveProfile}                     from "pages/Specialist/profile-remove";
import {ProfileMedia}                      from "pages/Specialist/profile-media";
import {ProfileMediaUpload}                from "pages/Specialist/profile-media-upload";
import {ProfileAvatarChange}               from "pages/Specialist/profile-avatar-change";

export interface RouteItem {
  path: string,
  element: JSX.Element,
  title?: string,
  children?: {
    path: string,
    element: JSX.Element,
  }[];
}

const routes: RouteItem[] = [
  {
    path:    "order",
    element: <Order />,
  },
  {
    path:    "registration",
    element: <Registration />,
  },
  {
    path:    "freedom",
    element: <FF />,
  },
  {
    path:    "egov",
    element: <Egov />,
  },
  {
    path:    "transfer",
    element: <TransferProfile />,
  },
  {
    path:    "non-resident",
    element: <NotCitizen />,
  },
  {
    path:    "document",
    element: <WithDocument />,
  },
  {
    path:    "transfer-complete",
    element: <TransferComplete />,
  },
  {
    path:    "specialist-reviews/:id/:service_id?",
    element: <SpecialistReviews />,
  },
  {
    path:    "client-reviews/:id",
    element: <ClientReviews />,
  },
  {
    path:    "specialist-self-reviews/:service_id?",
    element: <SelfReviews />,
  },
  {
    path:     "tariffs/*",
    element:  <Outlet />,
    children: [
      {
        path:    "",
        element: <PurchaseListPage />,
      },
      {
        path:    "periods",
        element: <PeriodsListPage />,
      },
      {
        path:    "options/:period/:is_subscribe?",
        element: <TariffOptionsPage />,
      },
      {
        path:    "catalogue",
        element: <PurchaseCatalogueListPage />,
      },
      {
        path:    "catalogue/services",
        element: <PurchaseCatalogueServicesListPage />,
      },
    ],
  },
  {
    path:     "state/*",
    element:  <Outlet />,
    children: [
      {
        path:    "auto-renewal-cancel",
        element: <AutoRenewalCancel />,
      },
    ],
  },
  {
    path:     "credits/*",
    element:  <Outlet />,
    children: [
      {
        path:    "",
        element: <Credits />,
      },
      {
        path:    "schedule/:id",
        element: <CreditsSchedule />,
      },
    ],
  },
  {
    path:    "specialist-cash-loans",
    element: <CashLoans />,
  },
  {
    path:    "referral",
    element: <Referral />,
  },
  {
    path:     "profile/*",
    element:  <Outlet />,
    children: [
      {
        path:    "*",
        element: <SpecialistProfile />,
      },
      {
        path:    ":id/*",
        element: <SpecialistProfile />,
      },
      {
        path:    "about",
        element: <ProfileAbout />,
      },
      {
        path:    "prices",
        element: <ProfilePrices />,
      },
      {
        path:    "prices-service/:id",
        element: <PricesService />,
      },
      {
        path:    "prices-work/:id",
        element: <PricesWork />,
      },
      {
        path:    "remove",
        element: <RemoveProfile />,
      },
      {
        path:    "media/:id?",
        element: <ProfileMedia />,
      },
      {
        path:    "media/add",
        element: <ProfileMediaUpload />,
      },
      {
        path:    "avatar-change",
        element: <ProfileAvatarChange />,
      },
    ],
  },
  {
    path:    "client-profile/:id",
    element: <ClientProfile />,
  },
  {
    path:    "specialist-prices/:id/:service_id?",
    element: <SpecialistAppPrices />,
  },
]

const RootRoutes = () => {
  const {t} = useTranslation();

  return <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {!!route.children?.length && route.children.map((child) => (
          <Route key={child.path} path={child.path} element={child.element} />
        ))}
      </Route>
    ))}
    <Route path="/manual" element={
      <ManualRegistration
        navSubtitle={t("page.registration.manual_registration.header.description")}
        type="manual"
      />}
    />
  </Routes>
}
export default RootRoutes;