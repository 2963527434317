import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LabelItem}                  from "shared/ui";
import {Nullable}                   from "shared/model";
import {WorkUnitItemType}           from "../api";


interface OrderDetailsType {
  isAllowed: boolean;
  isAppPaymentWay: boolean;
  phone?: string;
  labels?: {
    addresses?: LabelItem[];
    app_payment?: LabelItem[];
    media?: LabelItem[];
  },
  date?: string;
  description: string;
  addresses: {
    address: string;
    city: { id: number, name: string }
    latitude: string;
    longitude: string;
  }[];
  price_to: Nullable<number>;
  workUnits?: WorkUnitItemType[];
  recommended_price: Nullable<{
    price?: string;
    work?: string;
  }>;
}

const initialState: {
  order: Nullable<OrderDetailsType>;
  isDuplicated: boolean;
} = {
  order:        null,
  isDuplicated: false,
};

const slice = createSlice({
  name:     "orderDetails",
  initialState,
  reducers: {
    setOrderDetails(state, action: PayloadAction<OrderDetailsType>) {
      state.order = action.payload
    },
    setTicketIsDuplicated(state, action: PayloadAction<boolean>) {
      state.isDuplicated = action.payload
    },
  },
})

export const {setOrderDetails, setTicketIsDuplicated} = slice.actions;
export default slice.reducer;


export const selectOrderDetails = (state: { orderDetails: typeof initialState }) => state.orderDetails.order;
export const selectTicketIsDuplicated = (state: {
  orderDetails: typeof initialState
}) => state.orderDetails.isDuplicated;