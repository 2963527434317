import React, {useEffect, useState}                                             from "react";
import {useTranslation}                                                         from "react-i18next";
import {useNavigate}                                                            from "react-router-dom";
import {NewPageLayout}                                                          from "entities/general";
import {SkeletonLayout}                                                         from "entities/skeleton";
import {useAppDispatch, useAppSelector}                                         from "store";
import {addNotification}                                                        from "store/features";
import {useFetchProfileAboutQuery, useSaveAboutMutation}                        from "shared/api";
import {InApp, Textarea}                                                        from "shared/ui";
import {useNativeHandler, useSetHeaders, useSkeletonThrottling, useToggleState} from "shared/lib";
import {NativeActions, selectProfile, setProfile}                               from "shared/model";
import {Icon, Button, NewButtonColor, NewButtonType}                            from "shared/v12ui";
import styles                                                                   from "./ProfileAbout.module.scss";


const i18n_prefix = "page.specialist.profile.about."
export default function ProfileAbout() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {isSuccess} = useSetHeaders();
  const profile = useAppSelector(selectProfile);

  const {data, isLoading: aboutIsLoading} = useFetchProfileAboutQuery(null, {
    skip:                      !isSuccess,
    refetchOnMountOrArgChange: true,
  });
  const [updateAbout, {isLoading, isSuccess: updateAboutIsSuccess}] = useSaveAboutMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: aboutIsLoading});

  const [value, setValue] = useState("");
  const [hasChanges, toggleHasChanges] = useToggleState();

  const handleConfirmCloseClick = () => {
    toggleHasChanges();
    setValue("");
    navigate("/profile");
  }

  const handleOnCloseClick = () => {
    if (value !== data?.about) {
      return toggleHasChanges();
    }
    return navigate("/profile");
  }

  const handleUpdateAbout = () => updateAbout(value);

  const footer = extendedLoading
    ? <SkeletonLayout height={46} theme="dark" />
    : <Button
      disabled={value.length > 300 || isLoading}
      loading={isLoading}
      onClick={handleUpdateAbout}
      text={t(`${i18n_prefix}button.save`)}
    />

  useEffect(() => {
    if (data && data.about) {
      setValue(data.about);
    }
  }, [data]);

  useEffect(() => {
    if (updateAboutIsSuccess) {
      handleConfirmCloseClick();
      dispatch(addNotification({text: t(`${i18n_prefix}toast`)}));
      dispatch(setProfile({
        ...profile,
        content: {
          ...profile?.content,
          //@ts-ignore
          specialist: {
            ...profile?.content.specialist,
            about:           value,
            about_moderated: false,
          },
        },
      }))
    }
  }, [updateAboutIsSuccess]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (hasChanges) {
      return toggleHasChanges();
    }
    return navigate("/profile");
  });

  return <>
    <NewPageLayout
      headerTitle={t(`${i18n_prefix}title`)}
      headerLeftIcon={<Icon className="text black" onClick={handleOnCloseClick}>keyboard_arrow_left</Icon>}
      isLoading={extendedLoading}
      footer={footer}
    >
      {extendedLoading
        ? <div className={styles.skeleton_textarea}>
          <SkeletonLayout height={132} borderRadius={16} />
          <SkeletonLayout height={17} borderRadius={4} width="95%" />
          <SkeletonLayout height={17} borderRadius={4} width="70%" />
        </div>
        : <Textarea
          value={value}
          counterCount={value.length}
          placeholder={t(`${i18n_prefix}placeholder`)}
          onChange={(event) => setValue(event.target.value)}
          hint={t(`${i18n_prefix}hint`)}
          errorMessage={value.length > 300 ? t(`${i18n_prefix}error`) : undefined}
        />}
    </NewPageLayout>

    <InApp
      open={hasChanges}
      onClose={toggleHasChanges}
      image="/illustrations/no-conversation-3.svg"
      headerText={t(`${i18n_prefix}inApp.title`)}
      headerSubtext={t(`${i18n_prefix}inApp.description`)}
    >
      <div className={styles.button_wrapper}>
        <Button
          text={t(`${i18n_prefix}inApp.button.cancel`)}
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          onClick={toggleHasChanges}
        />

        <Button
          hasSpace
          text={t(`${i18n_prefix}inApp.button.close`)}
          onClick={handleConfirmCloseClick}
        />
      </div>
    </InApp>
  </>
}