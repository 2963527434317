import React                     from "react";
import ReactDOM                  from "react-dom/client";
import App                       from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider}                from "react-redux";
import {store}                   from "./store/store";
import "./index.scss";
import i18n                      from "i18next";
import {initReactI18next}        from "react-i18next";
import ruTranslations            from "./i18n/ru";
import kzTranslations            from "./i18n/kk";
import "intl-pluralrules";
import "core-js/actual";

i18n
  .use(initReactI18next)
  .init({
    resources:     {
      ru: {
        translation: ruTranslations,
      },
      kk: {
        translation: kzTranslations,
      },
    },
    lng:           "ru",
    fallbackLng:   "ru",
    debug:         false,
    detection:     {
      order:  ["queryString", "cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  // <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  // </React.StrictMode>,
);
