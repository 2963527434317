import {Nullable}                             from "shared/model";
import {useSetHeaders, useSkeletonThrottling} from "shared/lib";
import {useFetchClientProfileQuery}           from "../api/clientProfileApi";

export const useFetchClientProfile = (id: Nullable<number>) => {
  const {isSuccess} = useSetHeaders();

  const {data: clientProfile, isLoading} = useFetchClientProfileQuery(id as number, {skip: !id && !isSuccess});

  const {extendedLoading} = useSkeletonThrottling({isLoading});

  return {clientProfile, extendedLoading}
}