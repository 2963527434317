import React                                          from "react";
import {useTranslation}                               from "react-i18next";
import {UseFieldArrayAppend, UseFieldArrayUpdate}     from "react-hook-form";
import {SuggestResponseItem}                          from "@yandex/ymaps3-types/imperative/suggest";
import {LngLat, YMapLocationRequest}                  from "@yandex/ymaps3-types";
import {
  selectGeolocationPermission,
  toggleGeoInApp,
}                                                     from "features/permissions";
import {Button, Icon, Input, NewList}                 from "shared/v12ui";
import {setActionForNative}                           from "shared/lib";
import {NativeActions}                                from "shared/model";
import {useAppDispatch, useAppSelector}               from "store";
import {selectCurrentCity}                            from "store/features";
import {useFetchAddressesHistoryQuery}                from "pages/order";
import {CreateState}                                  from "pages/order/model/createState";
import {formatAddressName, yaMapSearch, yaMapSuggest} from "../../model/yaMapHelpers";
import {AddressesItem}                                from "../../model/addressItem";
import styles                                         from "./SearchList.module.scss";


interface Props {
  openSearch: boolean;
  onSetOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  suggestValue: string;
  onSetSuggestValue: React.Dispatch<React.SetStateAction<string>>;
  addresses: AddressesItem[];
  updatedIndex?: number;
  addressName?: string;
  onSetAddress: UseFieldArrayAppend<CreateState, "addresses">
  onUpdateAddress: UseFieldArrayUpdate<CreateState, "addresses">;
  onSetLocation: React.Dispatch<React.SetStateAction<YMapLocationRequest>>;
  onMapClose: () => void;
}

export default function SearchList({
  openSearch,
  onSetOpenSearch,
  suggestValue,
  addresses,
  onSetSuggestValue,
  addressName,
  updatedIndex,
  onSetAddress,
  onUpdateAddress,
  onSetLocation,
  onMapClose,
}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const hasGeoPermission = useAppSelector(selectGeolocationPermission);
  const currentCity = useAppSelector(selectCurrentCity);

  const {data: historyList} = useFetchAddressesHistoryQuery();

  const [suggests, setSuggests] = React.useState<SuggestResponseItem[]>([]);
  const [searchHeight, setSearchHeight] = React.useState<string>("calc(100% - 9.4rem - env(safe-area-inset-top))");

  const handleToggleSearch = () => {
    if (searchHeight === "auto") {
      setSearchHeight("calc(100% - 9.4rem - env(safe-area-inset-top))");
      onSetOpenSearch(true);
    } else {
      setSearchHeight("auto");
      onSetOpenSearch(false);
    }
  }

  const handleOnOpenSearchClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    handleToggleSearch()
  }

  const handleGetGeolocationClick = () => {
    if (hasGeoPermission === false) {
      return dispatch(toggleGeoInApp(true));
    } else {
      setActionForNative(NativeActions.GET_GEO_COORDS);
    }
  }

  const handleOnSuggestClick = (text: string) => {
    if (!text) return;
    yaMapSearch({text: text, limit: 1}).then((res) => {
      onSetLocation({center: res[0].geometry?.coordinates as LngLat, zoom: 17});
    }).catch((error) => console.warn("ошибка search", error));
    handleToggleSearch();
  }

  const handleHistoryAddressClick = (coord: number[], value: string) => {
    yaMapSearch({text: `${coord}`, limit: 1}).then((res) => {
      onSetAddress({
        coord:     res[0].geometry?.coordinates as number[],
        name:      formatAddressName(value) ?? "",
        city_id:   currentCity?.id,
        city_name: currentCity?.name,
      });
      onMapClose();
    });
  }

  const handleSetAddressClick = () => {
    if (updatedIndex === undefined) {
      return yaMapSearch({text: suggestValue, limit: 1}).then((res) => {
        onSetAddress({
          coord:     res[0].geometry?.coordinates as number[],
          name:      formatAddressName(suggestValue) ?? "",
          city_id:   currentCity?.id,
          city_name: currentCity?.name,
        });
        onMapClose();
      });
    } else {
      const currentIndex = addresses?.findIndex((_, fieldIndex) => fieldIndex === updatedIndex);
      if (currentIndex !== -1) {
        return yaMapSearch({text: suggestValue, limit: 1}).then((res) => {
          onUpdateAddress(currentIndex, {
            coord:     res[0].geometry?.coordinates as number[],
            name:      formatAddressName(suggestValue) ?? "",
            city_id:   currentCity?.id,
            city_name: currentCity?.name,
          });
          onMapClose();
        });
      }
    }
  }

  const handleSearchPlace = (event: React.ChangeEvent<HTMLInputElement>) => {
    yaMapSuggest({text: `${currentCity?.name} ${event.target.value}`}).then((response) => {
      setSuggests(response)
    }).catch((error) => console.warn("ошибка suggest", error));
    onSetSuggestValue(event.target.value);
  }

  React.useEffect(() => {
    if (addressName) {
      onSetSuggestValue(addressName);
      yaMapSuggest({text: `${currentCity?.name} ${addressName}`}).then((response) => {
        setSuggests(response)
      }).catch((error) => console.warn("ошибка suggest", error));
    }
  }, [addressName]);

  return <div className={styles.search} style={{height: searchHeight}}>
    <div className={styles.search_wrapper}>
      {!openSearch && <div className={styles.geolocation_button} onClick={handleGetGeolocationClick}>
        <Icon iconColor="#828291" size={20}>navigation</Icon>
      </div>}
      {!openSearch
        ? <div className={styles.input_holder} onClick={handleOnOpenSearchClick}>
          <Input
            clearable
            icon="search"
            placeholder={t("widgets.map.search_placeholder")}
            value={suggestValue}
            readOnly={!openSearch}
            onChange={handleSearchPlace}
            hasSpace={false}
            subtext={(!openSearch && !hasGeoPermission)
              ? <div className={styles.permission_subtext}>
                <span>{t("widgets.map.get_geo_permission")}</span>
                <span onClick={handleGetGeolocationClick}>{t("widgets.map.resolve")}</span>
              </div>
              : undefined}
          />
        </div>
        : <Input
          clearable
          icon="search"
          placeholder={t("widgets.map.search_placeholder")}
          value={suggestValue}
          readOnly={!openSearch}
          onChange={handleSearchPlace}
          hasSpace={false}
          subtext={(!openSearch && !hasGeoPermission)
            ? <div className={styles.permission_subtext}>
              <span>{t("widgets.map.get_geo_permission")}</span>
              <span onClick={handleGetGeolocationClick}>{t("widgets.map.resolve")}</span>
            </div>
            : undefined}
        />}

      {openSearch && <button type="button" className={styles.map_button} onClick={handleToggleSearch}>
        {t("widgets.map.map")}
      </button>}
    </div>

    {!openSearch && <div className={styles.select_address_holder}>
      <Button type="button" onClick={handleSetAddressClick} text={t("common.select")} hasSpace />
    </div>}

    {openSearch && <ul className={styles.suggests_wrapper}>
      {!suggestValue
        ? historyList?.addresses?.map((address, index) => {
          return <li key={index}>
            <NewList
              hasSpace={false}
              icon="history"
              title={address.address}
              subtitle={address.city.name ? t("widgets.map.city", {city: address.city.name}) : undefined}
              onClick={() => handleHistoryAddressClick([address.longitude, address.latitude], address.address)}
            />
          </li>
        })
        : suggests.map((suggestItem, index) => {
          return <li key={index}>
            <NewList
              hasSpace={false}
              icon="place"
              title={formatAddressName(suggestItem.title.text) ?? ""}
              subtitle={suggestItem.subtitle?.text ?? undefined}
              onClick={() => handleOnSuggestClick(suggestItem.title.text)}
            />
          </li>
        })}
    </ul>}
  </div>
};