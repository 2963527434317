import React            from "react";
import {Icon}           from "shared/v12ui";
import {useToggleState} from "shared/lib";
import cn               from "classnames";
import styles           from "./styles.module.scss";


interface Props {
  title?: string;
  description?: string;
}

export default function Accordion({title, description}: Props) {
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const [expanded, toggleExpanded] = useToggleState();

  const [contentHeight, setContentHeight] = React.useState("0px");

  const handleToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    if (contentHeight === "0px") {
      setContentHeight(`${event.currentTarget.children[1].scrollHeight}px`);
    } else {
      setContentHeight("0px");
    }
    toggleExpanded();
  }

  return <div className={cn(styles.accordion, {[styles.open]: expanded})} onClick={handleToggle}>
    <div className={styles.header}>
      <Icon className={cn(styles.toggle_button, {[styles.toggle_button_open]: expanded})}>add</Icon>

      {title ?? ""}
    </div>
    <div
      ref={contentRef}
      className={styles.content}
      style={{maxHeight: expanded ? contentHeight : "0px"}}
    >
      {description ?? ""}
    </div>
  </div>
}