import React                                   from "react";
import {useTranslation}                        from "react-i18next";
import {UseFormSetError, UseFormSetValue}      from "react-hook-form";
import {BaseResponse}                          from "services/api";
import {Button, NewButtonColor, NewButtonType} from "shared/v12ui";
import {InApp}                                 from "shared/ui";
import {NativeActions, Nullable}               from "shared/model";
import {setActionForNative}                    from "shared/lib";
import {OrderRecommendationResponse}           from "../../api";
import {CreateState}                           from "../../model/createState";
import styles                                  from "./DescriptionInApp.module.scss";


interface Props {
  open: boolean;
  isEditOrder: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recommendations?: BaseResponse<OrderRecommendationResponse>;
  onIncreaseStep: () => void;
  setError: UseFormSetError<CreateState>;
  setValue: UseFormSetValue<CreateState>;
  successResponse: boolean;
  onSetSuccessResponse: React.Dispatch<React.SetStateAction<boolean>>;
  resetRecommendation: () => void;
}

export default function DescriptionInApp({
  recommendations,
  isEditOrder,
  successResponse,
  open,
  setOpen,
  onSetSuccessResponse,
  setValue,
  setError,
  onIncreaseStep,
  resetRecommendation,
}: Props) {
  const {t} = useTranslation();
  const alert = recommendations?.content?.alert;

  const handleToggleOpen = () => setOpen(prev => !prev);

  const handleCloseRecommendation = () => {
    setOpen(prev => !prev);
    onIncreaseStep();
  }

  const handleInAppButtonClick = (link: Nullable<string>) => {
    if (link === null) {
      handleToggleOpen();
    }
    if (link === "naimi://support/write") {
      handleToggleOpen();
      setActionForNative(NativeActions.OPEN_SUPPORT);
      return
    }
  }

  const onInAppButtonWithSuccessResponseClick = () => {
    handleToggleOpen();
    onIncreaseStep();
    onSetSuccessResponse(true);
    resetRecommendation();
  }

  const handleUpdateDescriptionClick = () => {
    handleToggleOpen();
    setActionForNative(NativeActions.INPUT_FOCUS, {name: "description"});
    onSetSuccessResponse(true);
    resetRecommendation();
  }

  React.useEffect(() => {
    if (!recommendations) return;
    if (recommendations.status) {
      if (isEditOrder) {
        return onIncreaseStep();
      }
      if (!successResponse) {
        if (recommendations.content.alert) {
          setOpen(true);
        }
        if (recommendations.content.ticket) {
          setValue("recommendedPrice", recommendations.content.ticket.recommended_price);
          setValue("workUnits", recommendations.content.ticket.work_units);
        }
      } else {
        onIncreaseStep();
      }
    }

    if (!recommendations.status) {
      if (recommendations.content && recommendations.content.alert) {
        return setOpen(true);
      }
      if (recommendations.errors) {
        return setError("description", {message: recommendations.errors.description});
      }
    }
  }, [recommendations, successResponse]);

  return <>
    {alert && open && <InApp
      image={alert?.image
        ? `/illustrations/${alert.image}.svg`
        : "/illustrations/product-manual-2.svg"}
      headerText={recommendations?.content.alert?.title}
      headerSubtext={alert?.type ? alert.text :
        <div className={styles.inApp_subtext}>
          {recommendations?.content.alert?.text.split("\n").map((item, index) => {
            return <span key={index}>{item}</span>
          })}
        </div>}
      open={open}
      onClose={handleCloseRecommendation}
    >
      {alert?.buttons ?
        <div className="inApp_buttons">
          {alert.buttons.map((button, index) => {
            if (index === 0) {
              return <Button
                key={index}
                buttonType={NewButtonType.WRAPPED}
                buttonColor={NewButtonColor.GRAY}
                text={button.title}
                onClick={() => handleInAppButtonClick(button.link)}
              />
            }
            return <Button
              hasSpace
              key={index}
              text={button.title}
              onClick={() => handleInAppButtonClick(button.link)}
            />
          })}
        </div>
        : <div className="inApp_buttons">
          <Button
            buttonType={NewButtonType.WRAPPED}
            buttonColor={NewButtonColor.GRAY}
            text={t("page.order.first_step_description.update")}
            onClick={handleUpdateDescriptionClick}
          />
          <Button
            hasSpace
            text={t("page.order.first_step_description.submit")}
            onClick={onInAppButtonWithSuccessResponseClick}
          />
        </div>}
    </InApp>}
  </>
};